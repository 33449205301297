import graphdb1 from "../assets/03jan/graphdb1.png";

export default function GraphDB2() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              AWS Neptune Series
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Exploring GraphDB Using Gremlin
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            The below graph is an example of a social network with vertices as
            users and relationships (edges) as friends. In this network the
            depth can be of nth degree where n is the number of serial edges
            connected to the vertex. Lets first create a network using gremlin
            on jupyter.
          </p>
          <figure className="content-center">
            <img className="w-full rounded-lg" src={graphdb1} />
            <figcaption className="font-thin">
              Ref: AWS Social Network Notebook
            </figcaption>
          </figure>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            The below gremlin query creates user vertices and establishes
            'friend' edges between those. We can dwelve in to the details of
            gremlin queries in future. However, note that "addV" is used to
            create vertex, "as()" is used to assign the previous predicate a
            reference so it can used in the next predicate and "addE" is used to
            add edge.
          </p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-gremlin">
                g. addV('User').property('name','Bill').property('birthdate',
                '1988-03-22').<br></br>
                addV('User').property('name','Sarah').property('birthdate',
                '1992-05-03').<br></br>
                addV('User').property('name','Ben').property('birthdate',
                '1989-10-21').<br></br>
                addV('User').property('name','Lucy').property('birthdate',
                '1998-01-17').<br></br>
                addV('User').property('name','Colin').property('birthdate',
                '2001-08-14').<br></br>
                addV('User').property('name','Emily').property('birthdate',
                '1998-03-05').<br></br>
                addV('User').property('name','Gordon').property('birthdate',
                '2002-12-04').<br></br>
                addV('User').property('name','Kate').property('birthdate',
                '1995-02-12').<br></br>
                addV('User').property('name','Peter').property('birthdate',
                '2001-02-27').<br></br>
                addV('User').property('name','Terry').property('birthdate',
                '1989-10-02').<br></br>
                addV('User').property('name','Alistair').property('birthdate',
                '1992-06-30').<br></br>
                addV('User').property('name','Eve').property('birthdate',
                '2000-05-13').<br></br>
                addV('User').property('name','Gary').property('birthdate',
                '1998-09-20').<br></br>
                addV('User').property('name','Mary').property('birthdate',
                '1997-01-27').<br></br>
                addV('User').property('name','Charlie').property('birthdate',
                '1989-11-02').<br></br>
                addV('User').property('name','Sue').property('birthdate',
                '1994-03-08').<br></br>
                addV('User').property('name','Arnold').property('birthdate',
                '2002-07-23').<br></br>
                addV('User').property('name','Chloe').property('birthdate',
                '1988-11-04').<br></br>
                addV('User').property('name','Henry').property('birthdate',
                '1996-03-15').<br></br>
                addV('User').property('name','Josie').property('birthdate',
                '2003-08-21').<br></br>
                V().hasLabel('User').has('name','Sarah').as('a').V().<br></br>
                hasLabel('User').has('name','Bill').addE('FRIEND').to('a').property('strength',1).
                <br></br>
                V().hasLabel('User').has('name','Colin').as('a').V().hasLabel('User').has('name','Bill').
                <br></br>
                addE('FRIEND').to('a').property('strength',2).<br></br>
                V().hasLabel('User').has('name','Terry').as('a').V().hasLabel('User').has('name','Bill').
                <br></br>
                addE('FRIEND').to('a').property('strength',3).<br></br>
                V().hasLabel('User').has('name','Peter').as('a').V().hasLabel('User').has('name','Colin').
                <br></br>
                addE('FRIEND').to('a').property('strength',1).<br></br>
                V().hasLabel('User').has('name','Kate').as('a').V().hasLabel('User').has('name','Ben').
                <br></br>
                addE('FRIEND').to('a').property('strength',2).<br></br>
                V().hasLabel('User').has('name','Kate').as('a').V().hasLabel('User').has('name','Lucy').
                <br></br>
                addE('FRIEND').to('a').property('strength',3).<br></br>
                V().hasLabel('User').has('name','Eve').as('a').V().hasLabel('User').has('name','Lucy').
                <br></br>
                addE('FRIEND').to('a').property('strength',1).<br></br>
                V().hasLabel('User').has('name','Alistair').as('a').V().hasLabel('User').has('name','Kate').
                <br></br>
                addE('FRIEND').to('a').property('strength',2).<br></br>
                V().hasLabel('User').has('name','Gary').as('a').V().hasLabel('User').has('name','Colin').
                <br></br>
                addE('FRIEND').to('a').property('strength',3).<br></br>
                V().hasLabel('User').has('name','Gordon').as('a').V().hasLabel('User').has('name','Emily').
                <br></br>
                addE('FRIEND').to('a').property('strength',1).<br></br>
                V().hasLabel('User').has('name','Alistair').as('a').V().hasLabel('User').has('name','Emily').
                <br></br>
                addE('FRIEND').to('a').property('strength',3).<br></br>
                V().hasLabel('User').has('name','Terry').as('a').V().hasLabel('User').has('name','Gordon').
                <br></br>
                addE('FRIEND').to('a').property('strength',3).<br></br>
                V().hasLabel('User').has('name','Alistair').as('a').V().hasLabel('User').has('name','Terry').
                <br></br>
                addE('FRIEND').to('a').property('strength',1).<br></br>
                V().hasLabel('User').has('name','Gary').as('a').V().hasLabel('User').has('name','Terry').
                <br></br>
                addE('FRIEND').to('a').property('strength',2).<br></br>
                V().hasLabel('User').has('name','Mary').as('a').V().hasLabel('User').has('name','Terry').
                <br></br>
                addE('FRIEND').to('a').property('strength',3).<br></br>
                V().hasLabel('User').has('name','Henry').as('a').V().hasLabel('User').has('name','Alistair').
                <br></br>
                addE('FRIEND').to('a').property('strength',1).<br></br>
                V().hasLabel('User').has('name','Sue').as('a').V().hasLabel('User').has('name','Eve').
                <br></br>
                addE('FRIEND').to('a').property('strength',2).<br></br>
                V().hasLabel('User').has('name','Sue').as('a').V().hasLabel('User').has('name','Charlie').
                <br></br>
                addE('FRIEND').to('a').property('strength',3).<br></br>
                V().hasLabel('User').has('name','Josie').as('a').V().hasLabel('User').has('name','Charlie').
                <br></br>
                addE('FRIEND').to('a').property('strength',1).<br></br>
                V().hasLabel('User').has('name','Henry').as('a').V().hasLabel('User').has('name','Charlie').
                <br></br>
                addE('FRIEND').to('a').property('strength',2).<br></br>
                V().hasLabel('User').has('name','Henry').as('a').V().hasLabel('User').has('name','Mary').
                <br></br>
                addE('FRIEND').to('a').property('strength',3).<br></br>
                V().hasLabel('User').has('name','Mary').as('a').V().hasLabel('User').has('name','Gary').
                <br></br>
                addE('FRIEND').to('a').property('strength',1).<br></br>
                V().hasLabel('User').has('name','Henry').as('a').V().hasLabel('User').has('name','Gary').
                <br></br>
                addE('FRIEND').to('a').property('strength',2).<br></br>
                V().hasLabel('User').has('name','Chloe').as('a').V().hasLabel('User').has('name','Gary').
                <br></br>
                addE('FRIEND').to('a').property('strength',3).<br></br>
                V().hasLabel('User').has('name','Henry').as('a').V().hasLabel('User').has('name','Arnold').
                <br></br>
                addE('FRIEND').to('a').property('strength',1).<br></br>
                next()
              </code>
            </pre>
          </div>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            From the above network if we need to find the list of all the
            friends of "Bill", then we could use a query something like the
            below.
          </p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-gremlin">
                g.V().hasLabel('User').has('name', 'Bill').
                out('FRIEND').valueMap('name')
              </code>
            </pre>
          </div>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            A Few Graph Traversal Steps To Note:
          </p>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            1. addV(), addE() adds vertex and edges respectively
            <br></br>
            2. E(), V() - reads vertices and edges
            <br></br>
            3. To add a property use property() step. Example below:
            <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
              <pre>
                <code className="language-gremlin">
                  g.V(1).property('city','santa fe').property('state','new
                  mexico').valueMap()
                </code>
              </pre>
            </div>
            4. path() allows to examine the path that gremlin travelled through
            to get to a vertex. example below:
            <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
              <pre>
                <code className="language-gremlin">
                  gremlin> g.V().out().out().values('name').path()<br></br>
                  ==>[v[1],v[4],v[5],ripple]<br></br>
                  ==>[v[1],v[4],v[3],lop]
                </code>
              </pre>
            </div>
            5. graphson can be used to get results in JSON format.
            <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
              <pre>
                <code className="language-gremlin">
                  g.io("graph.json").read().iterate()<br></br>
                </code>
              </pre>
            </div>
          </p>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            References: <br></br>
            1.{" "}
            <a href="https://tinkerpop.apache.org/docs/current/reference/#io-step">
              https://tinkerpop.apache.org/docs/current/reference/#io-step
            </a>
            <br></br>
            2.{" "}
            <a href="https://github.com/aws-samples/amazon-neptune-samples/blob/master/neptune-sagemaker/notebooks/Getting-Started/03-Social-Network-Recommendations.ipynb">
              https://github.com/aws-samples/amazon-neptune-samples/blob/master/neptune-sagemaker/notebooks/Getting-Started/03-Social-Network-Recommendations.ipynb
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
