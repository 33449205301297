export default function Oversign() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              Typescript
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Overload Signatures
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Function overload signatures allows us to state the type of parameter and 
            the return type for a function. This is useful in scenarios when a combination
            of types including generics are applicable for a function. In the below Example
            the function accepts string as a parameter or a generic array. <br/>
            Any is a generic however it does not allow control over the return type hence 
            generics are much more useful. Having said that the function below can use any[]
            instead of T and it would still be valid.
            <br />
            (1) Refer to comment 1, the function accepts a string or a generic array. If the 
            parameter is a string then it returns a reverse string else it returns a reverse array. 
            Note that typescript requires "downlevelIteration": true in tsconfig for string spread operator. <br></br>
            (2) I then call the function with a string and print it out on console. <br/>
            (3) Comment 3, call with an array which then printed to console. <br/>
            (4) The overload signatures helps us to define the return type signature of a function. 
            In this case we simply define that the function when it accepts a string will return a string.
            ref comment 4. 
            <br></br>
            (5) Overload signature in comment 5 below is interesting because I have used generic type array 
            as parameter and return type. Basic, but to mention T can be named anything, &#60;YO&#62;
            is a valid generic type.
            <br />
            <br></br>
            Example:
          </p>
          <div className="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">
                // Comment 4 <br />
                function overloadsignatures(genparam: string): string;<br />
                <br />
                // Comment 5 <br />
                function overloadsignatures&#60;T&#62;(genparam: T[]): T[];<br
                />
                <br />
                // Comment 1 <br />
                function overloadsignatures&#60;T&#62;(genparam: string |
                T[])&#123;<br />
                return typeof genparam === "string"<br />
                &#63; [...genparam].reverse().join("")<br />
                : genparam.slice().reverse();<br />
                &#125;<br />
                <br />
                // Comment 2 <br />
                const reverseastring = overloadsignatures("hellothere!");<br />
                // Comment 3 <br />
                const reverseanarray = overloadsignatures([1,2,3,4,5,6]);<br />
                <br />
                console.log(reverseastring);<br />
                console.log(reverseanarray);<br />
              </code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
}
