import Consumption from "./components/Consumption";
import { Fragment, useCallback } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Facade from "./components/Facade";
import Footer from "./components/Footer";
import GraphDB from "./components/GraphDB";
import GraphDB2 from "./components/GraphDB2";
import Newsletter from "./components/Newsletter";
import Presigned from "./components/Presigned";
import TopHeading from "./components/TopHeading";
import Websockets from "./components/Websockets";
import Banner from "./components/Banner";
import Factory from "./components/Factory";
import Chain from "./components/Chain";
import Singleton from "./components/Singleton";
import Sigv4 from "./components/Sigv4";
import MLDatasets from "./components/MLDatasets";
import Sentinel from "./components/Sentinel";
import Customhooks from "./components/Customhooks";
import Appsync1 from "./components/Appsync1";
import Appsync2 from "./components/Appsync2";
import Appsync3 from "./components/Appsync3";
import Appsync4 from "./components/Appsync4";

import Slideover from "./components/Slideover";
import { useState } from "react";
import { useEffect } from "react/cjs/react.development";
import Appsync5 from "./components/Appsync5";
import LayersE from "./components/LayersE";
import KMS1 from "./components/KMS1";
import KMS2 from "./components/KMS2";
import FloatingActionButtons from "./components/FloatingActionButtons";
import Cognito1 from "./components/Cognito1";
import Cognito2 from "./components/Cognito2";

import ReactGA from 'react-ga';
import Typemodifier from "./components/Typemodifier";
import Oversign from "./components/Oversign";
import ScrapeCrypto from "./components/ScrapeCrypto";
import Idempotent from "./components/Idempotent";
import Moverload from "./components/Moverload";
import Apfailover from "./components/Apfailover";
import Staticstability from "./components/Staticstability";
import Reduxexample from "./components/Reduxexample";
import Extracttext from "./components/Extracttext";
import Multiplayer from "./components/Multiplayer";
import Chatgpt from "./components/Chatgpt";

const awsteam = [
  {
    name: "Multiplayer Game using AWS",
    handle: "35",
    href: "#multiplayer",
    date: "11-Feb-2022"
  },
  {
    name: "Static Stability with Route 53 ARC",
    handle: "33",
    href: "#sstability",
    date: "02-Feb-2022"
  },
  {
    name: "Failover Using Route 53",
    handle: "32",
    href: "#apfailover",
    date: "01-Feb-2022"
  },
  {
    name: "Overloading in Multitenant Apps",
    handle: "31",
    href: "#moverload",
    date: "31-Jan-2022"
  },
  {
    name: "Idempotent APIs",
    handle: "28",
    href: "#idempotent",
    date: "28-Jan-2022"
  },
  {
    name: "Implement Cognito Auth Journey",
    handle: "24",
    href: "#cognito2",
    date: "24-Jan-2022"
  },
  {
    name: "Implement Cognito Signup Journey",
    handle: "23",
    href: "#cognito1",
    date: "23-Jan-2022"
  },
  {
    name: "Encrypt and Decrypt Messages with KMS",
    handle: "22",
    href: "#kms2",
    date: "22-Jan-2022"
  },
  {
    name: "KMS Introduction and CLI Example",
    handle: "21",
    href: "#kms1",
    date: "21-Jan-2022"
  },
  {
    name: "Lambda Layers Example",
    handle: "20",
    href: "#layers",
    date: "20-Jan-2022"
  },
  {
    name: "Appsync subscriptions with ReactJS application",
    handle: "19",
    href: "#appsync5",
    date: "19-Jan-2022"
  },
  {
    name: "Appsync mutations with ReactJS application",
    handle: "18",
    href: "#appsync4",
    date: "18-Jan-2022"
  },
  {
    name: "Appsync Query with ReactJS application",
    handle: "17",
    href: "#appsync3",
    date: "17-Jan-2022"
  },
  {
    name: "Configure Appsync for ReactJS application",
    handle: "16",
    href: "#appsync2",
    date: "16-Jan-2022"
  },
  {
    name: "Appsync an Introduction",
    handle: "15",
    href: "#appsync1",
    date: "15-Jan-2022"
  },
  {
    name: "Sentinel Quick Guide",
    handle: "13",
    href: "#sentinel",
    date: "13-Jan-2022"
  },
  {
    name: "SignatureV4",
    handle: "11",
    href: "#sigv4",
    date: "11-Jan-2022"
  },
  {
    name: "Websockets - Simple Recipe to try",
    handle: "06",
    href: "#websockets",
    date: "06-Jan-2022"
  },
  {
    name: "Newsletter using SES",
    handle: "05",
    href: "#newsletter",
    date: "05-Jan-2022"
  },
  {
    name: "Short note on Presigned URLs",
    handle: "04",
    href: "#presigned",
    date: "04-Jan-2022"
  },
  {
    name: "Exploring GraphDB Using Gremlin",
    handle: "03",
    href: "#graphdb2",
    date: "03-Jan-2022"
  },
  {
    name: "Introduction to GraphDB",
    handle: "02",
    href: "#graphdb",
    date: "02-Jan-2022"
  }
];

const reactteam = [
  {
    name: "Redux Example Implementation",
    handle: "34",
    href: "#reduxexample",
    date: "8-Feb-2022"
  },
  {
    name: "Scrape Webpage Using Node",
    handle: "27",
    href: "#scrape",
    date: "27-Jan-2022"
  },
  {
    name: "Overload Signatures",
    handle: "26",
    href: "#oversign",
    date: "26-Jan-2022"
  },
  {
    name: "Mapped Type Modifiers",
    handle: "25",
    href: "#typemodifier",
    date: "25-Jan-2022"
  },
  {
    name: "useYourTimeWisely() Custom Hooks",
    handle: "14",
    href: "#customhooks",
    date: "14-Jan-2022"
  },
  {
    name: "Singleton Pattern",
    handle: "10",
    href: "#singleton",
    date: "10-Jan-2022"
  },
  {
    name: "Chain of Responsibility Pattern",
    handle: "09",
    href: "#chain",
    date: "09-Jan-2022"
  },
  {
    name: "Factory Pattern",
    handle: "08",
    href: "#factory",
    date: "08-Jan-2022"
  },
  {
    name: "Facade Pattern",
    handle: "07",
    href: "#facade",
    date: "07-Jan-2022"
  }
];

const mlteam = [
  {
    name: "Extract text from image",
    handle: "35",
    href: "#extracttext",
    date: "09-Feb-2022"
  },
  {
    name: "Five Large ML Datasets",
    handle: "12",
    href: "#mldatasets",
    date: "12-Jan-2022"
  }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function App() {
  const [open, setOpen] = useState(false);
  const [tabaws, setTabaws] = useState(true);
  const [tabreact, setTabreact] = useState(false);
  const [tabml, setTabml] = useState(false);

  useState(() => {
    setTimeout(() => {
      setOpen(true);
    }, 5000);

    ReactGA.initialize('UA-217910153-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const tabs = [
    { name: "AWS", href: "#awsmenu", current: tabaws },
    { name: "React & Node", href: "#reactmenu", current: tabreact },
    { name: "ML & PY", href: "#mlmenu", current: tabml }
  ];

  return (
    <div
      onClick={() => {
        setOpen(!open);
      }}
    >
      <TopHeading />

      <div id="chatgpt">
        <br />
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              24-Feb-2023
            </span>
          </div>
        </div>
        <Chatgpt />
        <br />
      </div>

      <div id="multiplayer">
        <br />
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              11-Feb-2022
            </span>
          </div>
        </div>
        <Multiplayer />
        <br />
      </div>
      <div id="extracttext">
        <br />
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              09-Feb-2022
            </span>
          </div>
        </div>
        <Extracttext />
        <br />
      </div>

      <div id="reduxexample">
        <br />
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              08-Feb-2022
            </span>
          </div>
        </div>
        <Reduxexample />
        <br />
      </div>

      <div id="sstability">
        <br />
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              02-Feb-2022
            </span>
          </div>
        </div>
        <Staticstability />
        <br />
      </div>

      <div id="apfailover">
        <br />
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              01-Feb-2022
            </span>
          </div>
        </div>
        <Apfailover />
        <br />
      </div>

      <div id="moverload">
        <br />
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              31-Jan-2022
            </span>
          </div>
        </div>
        <Moverload />
        <br />
      </div>

      <div id="idempotent">
        <br />
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              28-Jan-2022
            </span>
          </div>
        </div>
        <Idempotent />
        <br />
      </div>

      <div id="scrape">
        <br />
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              27-Jan-2022
            </span>
          </div>
        </div>
        <ScrapeCrypto />
        <br />
      </div>

      <div id="oversign">
        <br />
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              26-Jan-2022
            </span>
          </div>
        </div>
        <Oversign />
        <br />
      </div>

      <div id="typemodifier">
        <br />
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              25-Jan-2022
            </span>
          </div>
        </div>
        <Typemodifier />
        <br />
      </div>

      <div id="cognito2">
        <br />
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              24-Jan-2022
            </span>
          </div>
        </div>
        <Cognito2 />
        <br />
      </div>

      <div id="cognito1">
        <br />
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              23-Jan-2022
            </span>
          </div>
        </div>
        <Cognito1 />
        <br />
      </div>

      <div id="kms2">
        <br />
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              22-Jan-2022
            </span>
          </div>
        </div>
        <KMS2 />
        <br />
      </div>

      <div id="kms1">
        <br />
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              21-Jan-2022
            </span>
          </div>
        </div>
        <KMS1 />
        <br />
      </div>

      <div id="layers">
        <br />
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              20-Jan-2022
            </span>
          </div>
        </div>
        <LayersE />
        <br />
      </div>

      <div id="appsync5">
        <br />
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              19-Jan-2022
            </span>
          </div>
        </div>
        <Appsync5 />
        <br />
      </div>

      <div id="appsync4">
        <br />
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              18-Jan-2022
            </span>
          </div>
        </div>
        <Appsync4 />
        <br />
      </div>

      <div id="appsync3">
        <br />
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              17-Jan-2022
            </span>
          </div>
        </div>
        <Appsync3 />
        <br />
      </div>
      <div id="appsync2">
        <br />
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              16-Jan-2022
            </span>
          </div>
        </div>
        <Appsync2 />
        <br />
      </div>
      <div id="appsync1">
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              15-Jan-2022
            </span>
          </div>
        </div>
        <Appsync1 />
        <br />
      </div>
      <div id="customhooks">
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              14-Jan-2022
            </span>
          </div>
        </div>
        <Customhooks />
        <br />
      </div>
      <div id="sentinel">
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              13-Jan-2022
            </span>
          </div>
        </div>
        <Sentinel />
        <br />
      </div>
      <div id="mldatasets">
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              12-Jan-2022
            </span>
          </div>
        </div>
        <MLDatasets />
        <br />
      </div>
      <div id="sigv4">
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              11-Jan-2022
            </span>
          </div>
        </div>
        <Sigv4 />
        <br />
      </div>
      <div id="singleton">
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              10-Jan-2022
            </span>
          </div>
        </div>
        <Singleton />
        <br />
      </div>
      <div id="chain">
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              09-Jan-2022
            </span>
          </div>
        </div>
        <Chain />
        <br />
      </div>
      <div id="factory">
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              08-Jan-2022
            </span>
          </div>
        </div>
        <Factory />
        <br />
      </div>
      <div id="facade">
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              07-Jan-2022
            </span>
          </div>
        </div>
        <Facade />
        <br />
      </div>
      <div id="websockets">
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              06-Jan-2022
            </span>
          </div>
        </div>
        <Websockets />
        <br />
      </div>
      <div id="newsletter">
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              05-Jan-2022
            </span>
          </div>
        </div>
        <Newsletter />
        <br />
      </div>
      <div id="presigned">
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              04-Jan-2022
            </span>
          </div>
        </div>
        <Presigned />
        <br />
      </div>
      <div id="graphdb2">
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              03-Jan-2022
            </span>
          </div>
        </div>
        <GraphDB2 />
        <br />
      </div>
      <div id="graphdb">
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              02-Jan-2022
            </span>
          </div>
        </div>
        <GraphDB />
        <br />
      </div>
      <div id="consumption">
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 bg-white text-sm text-gray-500">
              01-Jan-2022
            </span>
          </div>
        </div>
        <Consumption />
      </div>
      {/* <Banner /> */}
      <Footer />

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden"
          onClose={setOpen}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16" >
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
                
              >
                <div className="w-screen max-w-md" >
                  <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll" >
                    <div className="p-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Choose an article
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-gray-200">
                      <div className="px-6">
                        <nav
                          className="-mb-px flex space-x-6"
                          x-descriptions="Tab component"
                        >
                          {tabs.map(tab =>
                            <a
                              key={tab.name}
                              href={tab.href}
                              className={classNames(
                                tab.current
                                  ? "border-indigo-500 text-indigo-600"
                                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                "whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                              )}
                              onClick={() => {
                                if (tab.name == "AWS") {
                                  setTabaws(true);
                                  setTabreact(false);
                                  setTabml(false);
                                } else if (tab.name == "React & Node") {
                                  setTabreact(true);
                                  setTabaws(false);
                                  setTabml(false);
                                } else if (tab.name == "ML & PY") {
                                  setTabml(true);
                                  setTabreact(false);
                                  setTabaws(false);
                                }
                              }}
                            >
                              {tab.name}
                            </a>
                          )}
                        </nav>
                      </div>
                    </div>
                    {tabaws
                      ? <div id="awsmenu">
                          <ul
                            role="list"
                            className="flex-1 divide-y divide-gray-200 overflow-y-auto"
                            
                          >
                            {awsteam.map(person =>
                              <li key={person.handle} >
                                <div className="relative group py-6 px-5 flex items-center">
                                  <a
                                    href={person.href}
                                    className="-m-1 flex-1 block p-1"
                                    
                                  >
                                    <div
                                      className="absolute inset-0 group-hover:bg-gray-50"
                                      aria-hidden="true"
                                    />
                                    <div className="flex-1 flex items-center min-w-0 relative">
                                      <div className="ml-4 truncate">
                                        <a href={person.href}>
                                          <p className="text-sm font-medium text-gray-900 truncate">
                                            {person.name}
                                          </p>
                                        </a>
                                        <a href={person.href}>
                                          <p className="text-sm font-medium text-gray-300 truncate">
                                            {person.date}
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      : null}
                    {tabreact
                      ? <div id="reactmenu">
                          <ul
                            role="list"
                            className="flex-1 divide-y divide-gray-200 overflow-y-auto"
                          >
                            {reactteam.map(person =>
                              <li key={person.handle}>
                                <div className="relative group py-6 px-5 flex items-center">
                                  <a
                                    href={person.href}
                                    className="-m-1 flex-1 block p-1"
                                  >
                                    <div
                                      className="absolute inset-0 group-hover:bg-gray-50"
                                      aria-hidden="true"
                                    />
                                    <div className="flex-1 flex items-center min-w-0 relative">
                                      <div className="ml-4 truncate">
                                        <a href={person.href}>
                                          <p className="text-sm font-medium text-gray-900 truncate">
                                            {person.name}
                                          </p>
                                        </a>
                                        <a href={person.href}>
                                          <p className="text-sm font-medium text-gray-300 truncate">
                                            {person.date}
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      : null}
                    {tabml
                      ? <div id="mlmenu">
                          <ul
                            role="list"
                            className="flex-1 divide-y divide-gray-200 overflow-y-auto"
                          >
                            {mlteam.map(person =>
                              <li key={person.handle}>
                                <div className="relative group py-6 px-5 flex items-center">
                                  <a
                                    href={person.href}
                                    className="-m-1 flex-1 block p-1"
                                  >
                                    <div
                                      className="absolute inset-0 group-hover:bg-gray-50"
                                      aria-hidden="true"
                                    />
                                    <div className="flex-1 flex items-center min-w-0 relative">
                                      <div className="ml-4 truncate">
                                        <a href={person.href}>
                                          <p className="text-sm font-medium text-gray-900 truncate">
                                            {person.name}
                                          </p>
                                        </a>
                                        <a href={person.href}>
                                          <p className="text-sm font-medium text-gray-300 truncate">
                                            {person.date}
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      : null}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default App;
