import gremlin1 from "../assets/gremlin1.png";
import routes1 from "../assets/websocket/routes.png";
import stage1 from "../assets/websocket/stage.png";
import csession from "../assets/websocket/clientsession.png";
import ssession from "../assets/websocket/serverconnect.png";

export default function Factory() {
  var factory = `
  namespace FMPattern {
    export interface ProductType {
        method(param?: any) : void;
    }
    export class ProductA implements ProductType {
        method = (param?: any) => {
            return "Method of ProductA";
        }
    }
    export class ProductB implements ProductType {
        method = (param?: any) => {
            return "Method of ProductB";
        }
    }
    export namespace ProductFactory {
        export function createPricing(type: string) : ProductType {
            if (type === "A") {
                return new ProductA();
            } else if (type === "B") {
                return new ProductB();
            }
            return null;
        }
    }
}
`;

  var functioncall = `namespace FMPattern {
	export namespace Demo {
		export function show() : void {
		    var a: FMPattern.ProductType = FMPattern.ProductFactory.createPricing("A");
		    var b: FMPattern.ProductType = FMPattern.ProductFactory.createPricing("B");

		    console.log(a.method());
		    console.log(b.method());
		};
	}
}`;

  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              Typescript Design Patterns
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Factory Pattern
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Factory pattern can be used to define an overarching interface 
            and let classes to implement and instantiate based on a scenario. 
            For instance, in a wholesale ecommerce scenario such as alibaba.com,
            the application would could offer discount for products that are of low demand,
            increase price for products of high demand and maybe offer discount if two
            similar products are ordered together. In such a scenario, the interface could
            define the pricing method and the classes can implement the pricing method
            depending on the type of the product. Example below.<br>
            </br>
            Factory Interface:
          </p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">{factory}</code>
            </pre>
          </div>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Function call:
          </p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">{functioncall}</code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
}
