import gremlin1 from "../assets/gremlin1.png";
import routes1 from "../assets/websocket/routes.png";
import stage1 from "../assets/websocket/stage.png";
import csession from "../assets/websocket/clientsession.png";
import ssession from "../assets/websocket/serverconnect.png";

export default function Singleton() {
  var singleton = `namespace SingletonPattern {
    export class Singleton {
        private static singleton: Singleton;
        private constructor() {
        }
        public static getInstance(): Singleton {
            if (!Singleton.singleton) {
                // store the instance in a variable
                Singleton.singleton = new Singleton();
            }
            // return the singleton object
            return Singleton.singleton;
        }
    }
}
`;

  var functioncall = `namespace SingletonPattern {
	export namespace Demo {
		export function show() : void {
			const singleton1 = SingletonPattern.Singleton.getInstance();
			const singleton2 = SingletonPattern.Singleton.getInstance();

			if (singleton1 === singleton2) {
				console.log("Both the object are equal");
			} else {
				console.log("This will never get executed");
			}
		}
	}
}`;

  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              Typescript Design Patterns
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Singleton Pattern
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Singleton pattern can be used to ensure that a class is instantiated just once globally.
            A real life example would be the shopping cart in an ecommerce application. If the cart 
            class is intantiated multiple times the items stored by a user would be lost. For a given
            session the cart object must be kept alive so that user can add, remove and checkout. Example below:<br>
            </br>
            Singleton Definition:
          </p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">{singleton}</code>
            </pre>
          </div>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Function call:
          </p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">{functioncall}</code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
}
