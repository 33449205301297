import {
  PlusSmIcon as PlusSmIconSolid,
  MinusSmIcon
} from "@heroicons/react/solid";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decrement, increment } from "./redux/counter";

export default function Reduxexample() {
  //   const [count, setCount] = useState(0);
  const { count } = useSelector(state => state.counter);
  const dispatch = useDispatch();
  var sliceV = `
import { createSlice } from "@reduxjs/toolkit";
export const counterExample = createSlice({
  name: "counter",
  initialState: {
    count: 0
  },
  reducers: {
    increment: (state) => {
      state.count += 1;
    },
    decrement: (state) => {
      state.count -= 1;
    }
  }
});
export const { increment, decrement } = counterExample.actions;
export default counterExample.reducer;
  `;

  var storeV = `
  import { configureStore } from "@reduxjs/toolkit";
  import counterReducer from "./counter";
  export default configureStore({
    reducer: {
      counter: counterReducer
    }
  });
    `;

    var funcV = 
`
...
import { useDispatch, useSelector } from "react-redux";
import { decrement, increment } from "./redux/counter";
...
export default function Reduxexample() {
    const { count } = useSelector(state => state.counter);
    const dispatch = useDispatch();
...
<button onClick={() => dispatch(increment())} />
...
<button onClick={() => dispatch(decrement())} />
...
<label>{count}</label>
      `;

    var indexV = `
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { Provider } from "react-redux";
import store from "./components/redux/store";
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
        <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
    );    
      `;

  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              REACT REDUX
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Redux Example Implementation
            </span>
          </h1>
          <br />
          <div className="self-auto text-center ">
            <button
              type="button"
              className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => dispatch(increment())}
            >
              <PlusSmIconSolid className="h-5 w-5" aria-hidden="true" />
            </button>
            <span>&nbsp;&nbsp;</span>
            <button
              type="button"
              className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => dispatch(decrement())}
            >
              <MinusSmIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <span>&nbsp;&nbsp;</span>
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-indigo-100 text-indigo-800">
              <svg
                className="-ml-0.5 mr-1.5 h-4 w-2 text-indigo-400"
                fill="currentColor"
                viewBox="0 0 8 8"
              >
                <circle cx={4} cy={4} r={3} />
              </svg>
              {count}
            </span>
          </div>
          <div>
            <p className="text-center mt-8 text-xl text-gray-500 leading-8">
              A short example (try👆) on how to implement redux store using &nbsp;
              <a
                className="underline text-indigo-600"
                href="https://redux-toolkit.js.org"
              >
                redux toolkit
              </a>.
            </p>
            <p className="text-indigo-600 text-center">Slice:</p>
            <div className="mt-1 prose prose-slate mx-auto lg:prose-lg">
              <pre>
                <code className="language-Javascript">
                  {sliceV}
                </code>
              </pre>
            </div>
            <p className="text-indigo-600 text-center">Store:</p>
            <div className="mt-1 prose prose-slate mx-auto lg:prose-lg">
              <pre>
                <code className="language-Javascript">
                  {storeV}
                </code>
              </pre>
            </div>
            <p className="text-indigo-600 text-center">index.js:</p>
            <div className="mt-1 prose prose-slate mx-auto lg:prose-lg">
              <pre>
                <code className="language-Javascript">
                  {indexV}
                </code>
              </pre>
            </div>
            <p className="text-indigo-600 text-center">
              Function implementation:
            </p>
            <div className="mt-1 prose prose-slate mx-auto lg:prose-lg">
              <pre>
                <code className="language-Javascript">
                  {funcV}
                </code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
