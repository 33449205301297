import gremlin1 from "../assets/gremlin1.png";
import routes1 from "../assets/websocket/routes.png";
import stage1 from "../assets/websocket/stage.png";
import csession from "../assets/websocket/clientsession.png";
import ssession from "../assets/websocket/serverconnect.png";

export default function Appsync5() {
  var customhook = ` import { useState, useEffect } from 'react';

  function useYourTimeWisely(activity) {
    const [isUseful, setIsUseful] = useState(null);
    useEffect(() => {
      function checkUsefulnessAPI(activity) {
        //API Call
        setIsUseful(receivedStatus);
      }  
    });
    return isUseful;
  }
`;

  var hookcall = ` function UsefulTime(props) {
    const isUseful = useYourTimeWisely(props.activity);
  
    if (!isUseful) {
      return 'Waste of time ..';
    } else {
        return 'good job!';
    }
  }
`;

  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              ReactJS + AWS Appsync
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Appsync subscriptions with ReactJS application
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            1. Import subscription from graphql directory
            <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
              <pre>
                <code className="language-Javascript">
                  import &#123; onCreateApptest &#125; from
                  "./graphql/subscriptions";
                </code>
              </pre>
            </div>
            <br />
            2. Subscription example below using aws-amplify graphqlOperation
            subscribe and unsubscribe.
            <br />
            <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
              <p className="mt-8 text-xl text-gray-500 leading-8">
                In the below example I use useRef to create a reference for the
                data returned and useEffect to emulate componentdidmount and
                componentwillunmount.
              </p>
              <pre>
                <code className="language-Javascript">
                  const testSubscription = useRef(null);<br />
                  useEffect(() =&gt; &#123;<br />
                  //subscribe on mount<br />
                  testSubscription.current=<br />
                  API.graphql(graphqlOperation(onCreateApptest)).subscribe(&#123;<br
                  />
                  next: testData =&gt; &#123;<br />
                  console.log(testData.value.data.onCreateApptest.description);&#125;&#125;)<br
                  />
                  //unsubscribe on unmount<br />
                  return() =&gt; &#123;<br />
                  console.log("unsubscribe");<br />
                  API.graphql(graphqlOperation(onCreateApptest)).unsubscribe();&#125;&#125;,
                  []);
                </code>
              </pre>
              <p className="mt-8 text-xl text-gray-500 leading-8">
                    This can be testing by adding mutations on console or by using the mutation logic in the previous article.
                </p>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
}
