export default function Apfailover() {
    return (
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div
            className="relative h-full text-lg max-w-prose mx-auto"
            aria-hidden="true"
          >
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
                AWS DR
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Failover using Route 53
              </span>
            </h1>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              A common failover pattern is to maintain a primary and a secondary target, perform constant health checks on the 
               targets, if the primary target health deteriorates then redirect the traffic to secondary target. This is the most
               simplest form of DR - Active + Passive failover pattern. This pattern can be "effortlessly" implemented on AWS 
               using Route 53. 
               <br></br>
               Consider a design where a bunch of containers are using ECS as compute inside a VPC behind an application load balancer. 
               In this case the target would be the ALB DNS name. 
               <br></br>
               <br></br>
            <b>Mapping targets</b>
            <br></br>
            A simple single primary and secondary targets can be created using Route 53 traffic policy. 
            DNS A and AAAA records must be mapped to a failover rule. The failover rule can then be mapped to 
            a primary failover rule and a secondary failover rule. Route 53 will check the health of the target
            before traffic is routed to the primary endpoint and if the primary is unhealthy the traffic will be 
            routed to secondary target. Route 53 also offers option to switch primary and secondary thus enabling 
            pseudo blue-green at this stage. Pseudo because blue-green pattern requires far more considerations
            than just the traffic switching.
            <br></br>
            <br></br>
            The pattern can be extended to associate multiple primary and secondary failover targets. 
            A primary target can be extended to have additional primary and secondary, likewise a secondary 
            target can be extended to a much more complicated design. 
            <br></br>
<br></br>
            In addition to this a failover can be configured with wighted rules. For example, the A record could be 
            mapped to two target with a weight distribution of 1 and 3, for target A and target B respectively.
            In this case 1/4th of the time route 53 will route traffic to target A and the remaining 3/4th time to target B.
            If the weight is specified as zero for all records then the traffic will be attempted to be distributed equally.
            <br></br>
            <br>
            </br>
            Route 53 can thus be utilised to effectively implement active-active or active-passive failovers. There are further
            nuances around data replication, health checks and automation which will be discussed in future articles.
            </p>
          </div>
        </div>
      </div>
    );
  }
  