import DPSIRframework from "../assets/01jan/DPSIRframework.png";
import consumptionco2 from "../assets/01jan/consumptionco2.png";
import healthimpact from "../assets/01jan/healthimpact.png";
import Materiallifecycle from "../assets/01jan/Materiallifecycle.png";
import materialsgraph from "../assets/01jan/materialsgraph.png";

export default function Consumption() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
          <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              Environment
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              A case to reduce consumption
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            The basic needs when I grew up were some decent shelter, new clothes
            once a year, some food and thats it. TV, toys and even meat was a
            luxury. Then came the IT revolution and my basic human urge to
            survive which drove me to various cities in India and the world. The
            minute I had "enough" money, I started consuming like a "ravenous
            parasite". Buying clothes that I'd use rarely, electronics that just
            lies in a corner for years, notebooks that I never write on, shoes
            that I mostly don't wear, weights I never lift and so on, the list
            is virtually endless. So what is wrong with this, I could argue that
            it is my money and I am entitled to spend. There is a slight problem
            with that argument though, which is the negative net effect on the
            planet. The below image from UN assessment (ref below) depicts the
            rationale. In simple language that I'd understand, the more you earn
            the more you spend on unnecessary stuff which ends up as waste and
            causes environmental pressures. In recent times as I get older the
            "stuff" I purchase are not just to feed to my ego but also to show
            love, respect and at times loyalty. When did the world started
            valuing "things" over "words", I wonder.
          </p>
          <figure>
            <img
              className="w-full rounded-lg"
              src={DPSIRframework}
              alt=""
              width={1310}
              height={873}
            />
          </figure>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Climate change is just a big basket of things that impact the
            environment negatively. The research based on data available until
            yr 2005 has clearly identified health impacts due to envinromental
            pressures caused by human consumptions (again a large basket,
            detailed analysis in the next section). Refer to the below table
            with some highlevel indicators on health impacts due to human
            influence on environment. The research indicates a direct
            correlation to "waste" production, negative environment impact and
            human (us and our children) health. A ton of research and evidence
            is available to make a case for not eating meat, and it is highly
            tempting to go down that route. However, in this article I try to
            make a case against buying "unnecessary" clothes, toys and other
            "common luxury" items. Direct health impacts are much noticed and
            talked about however, the indirect impacts are almost never
            discussed. For instance indoor air quality at houses are mostly
            ignored, and "no" do not buy an indoor air quality monitor - just
            keep the windows open.
          </p>
          <figure>
            <img
              className="w-full rounded-lg"
              src={healthimpact}
              alt=""
              width={1310}
              height={873}
            />
          </figure>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Reasearch shows that the household consumption is a major
            contributor to greenhouse gas emissions. Manufactured products,
            clothing and food contributes to 38% of the total GHG emissions.
            This is simply mindblowing for one reason that it is directly under
            our (every single one of us) control. Together we can make a change.
            In the US, close to 2 metric tonne of CO2 equivalents are emmitted
            annually from clothing, furniture, food and communication sector
            alone. As a side note private transport sector's emissions is 2 MT
            on it own. The below graph shows "global" GHG impacts due to
            consumption. Clothing, Food and manufactured products accounts to
            approximately 18 MT of GHG emissions annually. This is wild.
          </p>
          <figure>
            <img
              className="w-full rounded-lg"
              src={consumptionco2}
              alt=""
              width={1310}
              height={873}
            />
          </figure>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            We all know that plastic is bad, although these days plastics are
            rebranded as "recyclable" which is mostly "green washing". Just a
            tiny reminder that most things we use ends up as waste. It is very
            weird to realise that everything around me will one day end up as a
            waste, including the laptop that I am using to write this article.
            The world is far from efficiently recycling waste. Most waste ends
            up in landfills or gets burnt.
          </p>
          <figure>
            <img
              className="w-full rounded-lg"
              src={Materiallifecycle}
              alt=""
              width={1310}
              height={873}
            />
          </figure>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Again the below is a reminder on the notorious materials that we as
            humankind use inevitably.
          </p>
          <figure>
            <img
              className="w-full rounded-lg"
              src={materialsgraph}
              alt=""
              width={1310}
              height={873}
            />
          </figure>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            To conclude, a lot of environmental decisions are not under our
            direct control and the elections are mostly about superficial
            promises. However collective action to reduce consumption seems to
            provide a glimmer of hope for the planet.
          </p>
          <p className="mt-8 text-xl text-gray-500 leading-8">References:</p>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            <a href="https://www.resourcepanel.org/reports/assessing-environmental-impacts-consumption-and-production">https://www.resourcepanel.org/reports/assessing-environmental-impacts-consumption-and-production</a>
          </p>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            <a href="https://www.eea.europa.eu/soer/2010/europe/consumption-and-environment">https://www.eea.europa.eu/soer/2010/europe/consumption-and-environment</a>
          </p>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            <a href="https://news.climate.columbia.edu/2020/12/16/buying-stuff-drives-climate-change/">https://news.climate.columbia.edu/2020/12/16/buying-stuff-drives-climate-change/</a>
          </p>
        </div>
      </div>
    </div>
  );
}
