import gremlin1 from "../assets/gremlin1.png";
import routes1 from "../assets/websocket/routes.png";
import stage1 from "../assets/websocket/stage.png";
import csession from "../assets/websocket/clientsession.png";
import ssession from "../assets/websocket/serverconnect.png";

export default function Facade() {
  var facade = `
  namespace FPattern {
    export class Class1 {
        public method1(): void {
            console.log("method1 of Interface1");
        }
    }
    export class Class2 {
        public method2(): void {
            console.log("method2 of Interface2");
        }
    }
    export class Class3 {
        public method3(): void {
            console.log("method3 of Interface3");
        }
    }
    export class Facade {
        private Class1: Class1 = new Class1();
        private Class2: Class2 = new Class2();
        private Class3: Class3 = new Class3();

        public op1(): void {
            console.log("op1 called");
            this.Class1.method1();
            this.Class2.method2();
        }

        public op2(): void {
            console.log("op2 called");
            this.Class1.method1();
            this.Class3.method3();
        }
    }
}
`;

  var functioncall = `namespace FPattern {
	export namespace Demo {
		export function show() : void {
		    var facade: FPattern.Facade = new FPattern.Facade();
			facade.op1();
			facade.op2();
		}
	}
}`;

  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              Typescript Design Patterns
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Facade Pattern
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Facade pattern can be used to simplify a service will multiple complex functionalities. 
            This is a structural pattern which can be used to unify multiple interfaces in to a 
            single interface and expose for consumption. As an example if a service has to be built
            to perform a bitcoin transaction, then first it would have to (a) check if the 
            sender has sufficient balance, (b) check if the receiver has a valid wallet and finally 
            (c) perform the transaction. In this case each of these operations would have to be performed 
            in a separate interface (or modules). The sufficient balance operation would be performed in account balance
            module, check valid wallet operation in wallet module and send money operation in the transaction
            module. If implemented using facade pattern this can all be unified in to a single interface 
            for user transaction. Example below.<br>
            </br>
            Facade Classes:
          </p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">{facade}</code>
            </pre>
          </div>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Function call:
          </p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">{functioncall}</code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
}
