import gremlin1 from "../assets/gremlin1.png";
import routes1 from "../assets/websocket/routes.png";
import stage1 from "../assets/websocket/stage.png";
import csession from "../assets/websocket/clientsession.png";
import ssession from "../assets/websocket/serverconnect.png";


// import "./GraphDB.js";
export default function Websockets() {
  var clientconnect = `wscat -c wss://44hvtcqk99.execute-api.eu-west-2.amazonaws.com/production`;
  var serverconnect = `awscurl --service execute-api -X POST -d "how are you" https://44hvtcqk99.execute-api.eu-west-2.amazonaws.com/production/@connections/LjJIedAqrPECEZQ= --region eu-west-2`;

  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              AWS API Gateway
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Websockets - Simple Recipe to try
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            This is a short expriment using websockets on API gateway.
            Websockets enable bi-directional communication between client and
            the backend. Scenarios could range from chat app to multi-player
            games. AWS websockets provide a fully managed service on API
            gateway, hence relatively faster to bootstrap compared to socket.io
            (which needs compute hence additional management of EC2/ECS). On AWS
            when you create a websocket the server integration is similar to a
            REST API. You would be able to integrate a "route" to a lambda
            function to process the API call. If you are familier with content
            routing where a message is routed based on the message type then the
            "route" on websocket are somewhat similar to this theoritically.
            When a client connects to the websocket channel the route "@connect"
            will be invoked. When the client disconnects "@disconnect" will be
            invoked. If the message is not a JSON message then the "@default"
            route will be invoked. For this exercise, create a websocket on API
            Gateway console and the route setup would look something like the
            below.
            <figure className="content-center">
              <img className="w-full rounded-lg" src={routes1} />
              <figcaption className="font-thin">
                Route setup on API gateway
              </figcaption>
            </figure>
            As mentioned above the route "$connect" will be invoked when a
            client connects to the server for the first time. At this point a
            tunnel will be established for two way communiation.<br></br>
            The below figure shows the stage where the API is deploy and notice
            it has a connection endpoint for the server and the WSS endpoint for
            the client to connect. When a client connects with a server a unique
            connection id will be supplied for the session.
            <figure className="content-center">
              <img className="w-full rounded-lg" src={stage1} />
              <figcaption className="font-thin">Websocket endpoints</figcaption>
            </figure>
            Now for this exercise lets try establishing a session from the
            client and send a message from server to the client. Run the below
            command in a terminal with the WSS endpoint from the stage.
          </p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">{clientconnect}</code>
            </pre>
          </div>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            The below image shows an established client session.
          </p>
          <figure className="content-center">
            <img className="w-full rounded-lg" src={csession} />
            <figcaption className="font-thin">
              Established Client Session
            </figcaption>
          </figure>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Now, from cloudwatch logs for this exercise you should be able to
            extract the connection ID. That is if the @connect route is
            integrated with a lambda function. Using the connection ID do an
            awscurl or a postman call to the https endpoint from the stage.
            Command below:
          </p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">{serverconnect}</code>
            </pre>
          </div>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            See below image, the message "how are you" from server would be
            received by the client. This exercise only focuses on a 1-2-1
            tunnel, i.e. every client that connects with a server will have a
            unique connection id.
          </p>
          <figure className="content-center">
            <img className="w-full rounded-lg" src={ssession} />
            <figcaption className="font-thin">
              Server message to client
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
}
