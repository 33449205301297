export default function Moverload() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              AWS Patterns
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Overload in Multitenant Applications
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Multitenant applications generate high value when resources are
            shared between tenants. In order to achieve effective resource
            sharing serverside services must be split in to data plane and
            control plane. Data plane services would be invoked directly by the
            client app while the control plane would hold configs for data plane
            to identify tenant specific resources.
            <br />
            <br />
            <b>Data plane calls to control plane</b>
            <br />
            Most commonly data plane services are designed to call control plane
            services, in order to retrieve configuations or push operational
            state. Operational states can be used to record usage data, failures
            and other telemetrics. In this case, control plan does not need to
            know which data plane services are required to access it. The design
            however has a few drawbacks:
            <br />
            1. Since a large number of data plane instances would be invoking a
            smaller fleet a simple bug could increase the frequency of
            invocations.
            <br />
            2. A small number of API retries might cause an overflow of retries.
            <br></br>
            3. Even a small change in environment could cause large number of concurrent invocations.
            <br></br>
            In this architecture some of these are unavoidable scenarios however the variable that could be changed is the control plane 
            itself. 
            <br></br>
            <br></br>
            <b>Solving scale mismatch</b>
            <br></br>
            (1) AWS recommends S3 as an intermediary. Though the solution seems obvious and simple
            this is actually an examplar of how sometimes simple solutions could be sufficient for complex problems. 
            Instead of exposing endpoint to the dataplane, config files can be stored in S3, which 
            the data plane can access at anypoint. The files can then be maintained/kept in sync by the control plane
            services. S3 can scale to support large number of invocations and the data plane will not be 
            impacted by the availability of control plane. 
            <br></br>
            (2) Lambda extensions can be used to cache config data from RDS, dynamodb, secrets manager and so on 
            to reduce cost and latency. Extension are added as part of layers and can be deployed using all 
            'traditional' tools, such as Terraform, serverless framework, CF and SAM. 
            <br></br>
            To summarise, in a Multitenant system it is not always a great idea to have configs retrieved 
            from API calls. Caching using lambda extensions and S3 offer great alternatives and are 
            advantageous interms of cost and latency. 
          </p>
        </div>
      </div>
    </div>
  );
}
