
export default function Typemodifier() {
  var customhook = ` import { useState, useEffect } from 'react';

  function useYourTimeWisely(activity) {
    const [isUseful, setIsUseful] = useState(null);
    useEffect(() => {
      function checkUsefulnessAPI(activity) {
        //API Call
        setIsUseful(receivedStatus);
      }  
    });
    return isUseful;
  }
`;

  var hookcall = ` function UsefulTime(props) {
    const isUseful = useYourTimeWisely(props.activity);
  
    if (!isUseful) {
      return 'Waste of time ..';
    } else {
        return 'good job!';
    }
  }
`;

  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              Typescript
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Mapped Type Modifiers
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Mapped types allows to reuse types and allows to create another type
            by iterating the property keys of the original type. In the example
            below,<br />
            (1) The interface IDevice is used to create a new type
            ReadonlyIphone <br />
            (2) The new type ReadonlyIphone used a modifier to convert all the
            inherited properties to readonly. I.e. once instantiated they cannot
            be modofied. Refer comment E in the below example. <br />
            (3) Modifier ? can be used to convert all the inherited properties
            to optional. Ref comment A.
            <br />
            (4) The type of all the inheritied properties can be completely
            changed, to a string in the below example. Ref comment B.
            <br />
            (5) Property types can be mofied to a composite union, example
            comment C. <br />
            (6) Another powerful example would be to remove all the optional
            properties from an inherited type. Ref example D.
            <br />
            <br />
            Example:
          </p>
          <div className="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">
                //Mapped type modifiers <br />
                interface IDevice&#123;<br />
                name: string;<br />
                model: number;<br />
                &#125;<br />
                type ReadonlyIphone = &#123;<br />
                readonly [property in keyof IDevice] : IDevice[property];<br />
                // Comment A <br />
                // Modify inherited props optional <br />
                // readonly [property in keyof IDevice]?: IDevice[property];<br
                /><br />
                // Comment B <br />
                // Modify inherited props types to string in this case<br />
                // readonly [property in keyof IDevice] : string;<br /><br />
                // Comment C<br />
                // Modify the type of inherited props to a union of original
                type and another type<br />
                // readonly [property in keyof IDevice] : IDevice[property] |
                string;<br /><br />
                // Comment D <br />
                // Remove all optionals from the inherited type<br />
                // readonly [property in keyof IDevice]-?: IDevice[property];<br
                />
                &#125;<br />
                const samsung: IDevice = &#123;name: 'galaxy', model: 50&#125;;<br
                />
                const readonlyIphone: ReadonlyIphone = &#123;name: 'iphone',
                model: 13&#125;;<br />
                samsung.model = 100; <br />
                // Comment E<br />
                // This will throw error because in the mapped type we have
                mentioned that the new type will be readonly.<br />
                // readonlyIphone.model = 15<br />
              </code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
}
