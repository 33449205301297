import gremlin1 from "../assets/gremlin1.png";
import gremlin1table from "../assets/gremlin1table.png";
// import "./GraphDB.js";
export default function Newsletter() {

    var reguser = `
    var params = {
        EmailAddress: "Don@exampledomain.com",
      };
      mailService.verifyEmailAddress(params, function (err, data) //callback
      {
        if (err) console.log(err, err.stack);
        else console.log(data);
      });
    `
    var sendemail = `
    var params = {
        Destination: {
          ToAddresses: [
            "Don@aol.com"
          ],
        },
        Message: {
          Body: {
            Html: {
              Data: "<h1>Test Email 1</h1>"
            },
          },
          Subject: {
            Data: "Test Email Subject"
          },
        },
        Source: "Don@exampledomain.com",
        Tags: [
          {
            Name: "EmailType",
            Value: "Test",
          },
      
        ],
      };
      mailService.sendEmail(params, function (err, data)//callback
       {
        if (err) console.log(err, err.stack);
        else console.log(data); 
      });      
    `

    var createtemplate = `
    var params = {
        Destination: {
          ToAddresses: [
            "Don@aol.com"
          ],
        },
        Message: {
          Body: {
            Html: {
              Data: "<h1>Test Email 1</h1>"
            },
          },
          Subject: {
            Data: "Test Email Subject"
          },
        },
        Source: "Don@exampledomain.com",
        Tags: [
          {
            Name: "EmailType",
            Value: "Test",
          },
      
        ],
      };
      mailService.sendEmail(params, function (err, data)//callback
       {
        if (err) console.log(err, err.stack);
        else console.log(data); 
      });
    `
    var sendemailusingtemplate = `
    var params = {
        Destination: { 
          ToAddresses: [
            'Don@aol.com'
          ]
        },
        Source: 'Don@exampledomain.com',
        Template: 'EmailTemplate1',
        TemplateData: JSON.stringify({mailType: 'template email'}),
        Tags: [
          {
            Name: 'EmailType',
            Value: 'TemplateEmail'
          },
        ],
      };
      mailService.sendTemplatedEmail(params, function(err, data) //callback
      {
        if (err) console.log(err, err.stack); 
        else     console.log(data);           
      });
    `
    var sendverusingtemplate = `
    var params = {
        EmailAddress: 'Don@aol.com', 
        TemplateName: 'EmailTemplate1',
      };
      mailService.sendCustomVerificationEmail(params, function(err, data) {
        if (err) console.log(err, err.stack); 
        else     console.log(data);           
      });
    `
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              AWS SES
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Newsletter using SES
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Newletter is a good way to build and maintain audience. There a a
            lot of out of the box services available in the market however the
            usual limitation would to be the ability to configure custom domain.
            The third parties either charge more or retain all users under their
            domain - for example substack. Also censorship, revenue share etc
            are a hude problem. Anyways, with this short tutorial I intend to
            show how to register a user and send emails programatically.
          </p>
          <p className="mt-8 text-xl font-bold text-gray-500 leading-8">Register user</p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">
              {reguser}
              </code>
            </pre>
          </div>
          <p className="mt-8 text-xl font-bold text-gray-500 leading-8">Send Email</p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">
              {sendemail}
              </code>
            </pre>
          </div>
          <p className="mt-8 text-xl font-bold text-gray-500 leading-8">Create Template</p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">
              {createtemplate}
              </code>
            </pre>
          </div>
          <p className="mt-8 text-xl font-bold text-gray-500 leading-8">Send Email Using Template</p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">
              {sendemailusingtemplate}
              </code>
            </pre>
          </div>
          <p className="mt-8 text-xl font-bold text-gray-500 leading-8">Verify Email Address Using Template</p>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            To send verification email using template you would have to request for production email 
            account access from AWS on the console.
          </p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">
              {sendverusingtemplate}
              </code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
}
