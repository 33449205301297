import gremlin1 from "../assets/gremlin1.png";
import routes1 from "../assets/websocket/routes.png";
import stage1 from "../assets/websocket/stage.png";
import csession from "../assets/websocket/clientsession.png";
import ssession from "../assets/websocket/serverconnect.png";
import folder from "../assets/20jan/20janfolderstructure.png";
import layerconfig from "../assets/20jan/20janlayerconfig.png";
import addlayer from "../assets/20jan/20janaddlayer.png";
import succ from "../assets/20jan/20jansuccess.png";
export default function Cognito1() {
  var customhook = ` import { useState, useEffect } from 'react';

  function useYourTimeWisely(activity) {
    const [isUseful, setIsUseful] = useState(null);
    useEffect(() => {
      function checkUsefulnessAPI(activity) {
        //API Call
        setIsUseful(receivedStatus);
      }  
    });
    return isUseful;
  }
`;

  var hookcall = ` function UsefulTime(props) {
    const isUseful = useYourTimeWisely(props.activity);
  
    if (!isUseful) {
      return 'Waste of time ..';
    } else {
        return 'good job!';
    }
  }
`;

  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              AWS Cognito
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Implement Cognito Signup Journey
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            The snippets below shows the signup journey implementations with
            cognito including the forgot password option. The prerequisie is to
            create a user pool on AWS and create an app client on the app
            integration section.
            <br />
            <br />
            Signup:
          </p>
          <div className="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">
                var AmazonCognitoIdentity =
                require('amazon-cognito-identity-js');<br />
                var CognitoUserPool = AmazonCognitoIdentity.CognitoUserPool;<br
                />
                var poolData = &#123;<br />
                UserPoolId: 'eu-west-2_test4jfge',<br />
                ClientId: '75ttd4testkpp581i87ev7rrbc'<br />
                &#125;;<br />
                var userPool = new
                AmazonCognitoIdentity.CognitoUserPool(poolData);<br />
                var attributeList = [];<br />
                var dataEmail = &#123;<br />
                Name: 'email',<br />
                Value: 'test@gmail.com',<br />
                &#125;;<br />
                var dataPhoneNumber = &#123;<br />
                Name: 'phone_number',<br />
                Value: '+15555555555',<br />
                &#125;;<br />
                var dataGivenName = &#123;<br />
                Name: 'given_name',<br />
                Value: 'testing',<br />
                &#125;;<br />
                var attributeEmail = new
                AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);<br />
                var attributePhoneNumber = new
                AmazonCognitoIdentity.CognitoUserAttribute(<br />
                dataPhoneNumber<br />
                );<br />
                var givenName = new
                AmazonCognitoIdentity.CognitoUserAttribute(dataGivenName);<br />
                attributeList.push(attributeEmail);<br />
                attributeList.push(attributePhoneNumber);<br />
                attributeList.push(givenName); //pet store<br />
                userPool.signUp(dataGivenName.Value, 'Password123!',
                attributeList, null, function(<br />
                err,<br />
                result<br />
                ) &#123;<br />
                if (err) &#123;<br />
                console.log(err.message || JSON.stringify(err));<br />
                return;<br />
                &#125;<br />
                var cognitoUser = result.user;<br />
                console.log('user name is ' + cognitoUser.getUsername());<br />
                &#125;);<br />
              </code>
            </pre>
          </div>

          <p className="mt-8 text-xl text-gray-500 leading-8">
            Confirm user signup with code sent by cognito:
          </p>
          <div className="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">
                var AmazonCognitoIdentity =
                require('amazon-cognito-identity-js');<br />
                var CognitoUserPool = AmazonCognitoIdentity.CognitoUserPool;<br
                />
                var poolData = &#123;<br />
                UserPoolId: 'eu-west-2_KYh04jfge',<br />
                ClientId: '75ttd4vfvokpp581i87ev7rrbc'<br />
                &#125;;<br />
                var userPool = new
                AmazonCognitoIdentity.CognitoUserPool(poolData);<br />
                var userData = &#123;<br />
                Username: 'testing',<br />
                Pool: userPool,<br />
                &#125;;<br />
                var cognitoUser = new
                AmazonCognitoIdentity.CognitoUser(userData);<br />
                cognitoUser.confirmRegistration('315155', true, function(err,
                result) &#123;<br />
                if (err) &#123;<br />
                console.log(err.message || JSON.stringify(err));<br />
                return;<br />
                &#125;<br />
                console.log('call result: ' + result);<br />
                &#125;);<br />
              </code>
            </pre>
          </div>

          <p className="mt-8 text-xl text-gray-500 leading-8">
            Resend confirmation code:
          </p>
          <div className="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">
                var AmazonCognitoIdentity =
                require('amazon-cognito-identity-js');<br />
                var CognitoUserPool = AmazonCognitoIdentity.CognitoUserPool;<br
                />
                var poolData = &#123;<br />
                UserPoolId: 'eu-west-2_KYh04jfge',<br />
                ClientId: '75ttd4vfvokpp581i87ev7rrbc'<br />
                &#125;;<br />
                var userPool = new
                AmazonCognitoIdentity.CognitoUserPool(poolData);<br />
                var userData = &#123;<br />
                Username: 'testing',<br />
                Pool: userPool,<br />
                &#125;;<br />
                var cognitoUser = new
                AmazonCognitoIdentity.CognitoUser(userData);<br />
                cognitoUser.resendConfirmationCode(function(err, result) &#123;<br
                />
                if (err) &#123;<br />
                console.log(err.message || JSON.stringify(err));<br />
                return;<br />
                &#125;<br />
                console.log('call result: ' + result);<br />
                &#125;);<br />
              </code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
}
