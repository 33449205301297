export default function ScrapeCrypto() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              Node
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Scrape Webpage Using Node
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            This snippet uses Cheerio library to scrape coinmarketcap and get
            the current price of cryptos that are trending. Its pretty much self
            explanatory, I extracted the div name and component types by simply
            inspecting the site. Also $ is just a convention for cheerio object,
            the rest is just plain js.
            <br />
            Purely for educational purposes ;)
          </p>
          <div className="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">
                const axios = require("axios").default;<br />
                const cheerio = require("cheerio");<br />
                const URL =
                "https://coinmarketcap.com/trending-cryptocurrencies/";<br />
                const PROPS = &#123;<br />
                0: "stars",<br />
                1: "Num",<br />
                2: "Name",<br />
                3: "Price"<br />
                &#125;;<br />
                const getProp = i =&gt; PROPS[i];<br />
                const fetchHtml = async url =&gt; &#123;<br />
                const &#123; data &#125; = await axios.get(url);<br />
                return data;<br />
                &#125;;<br />
                const scrape = async () =&gt; &#123;<br />
                const html = await fetchHtml(URL);<br />
                const $ = cheerio.load(html);<br />
                const results = $("#__next")<br />
                .find("tbody &gt; tr")<br />
                .toArray()<br />
                .map(element =&gt; parseResult($, element))<br />
                .map(element =&gt; prettyPrint(element));<br />
                return results;<br />
                &#125;;<br />
                const parseResult = ($, element) =&gt; &#123;<br />
                const result = &#123;&#125;;<br />
                $(element).find("td").each((i, elm) =&gt; (result[getProp(i)] =
                $(elm).text()));<br />
                return result;<br />
                &#125;;<br />
                const prettyPrint = result =&gt; `$&#123;result.Name&#125;'s
                today's price $&#123;result.Price&#125;`;<br />
                async function process() &#123;<br />
                const json = await scrape();<br />
                console.log(json);<br />
                &#125;<br />
                process();
              </code>
            </pre>
            Output after pretty print, an array of column 1 and 2 from the webpage:
            <pre>
              <code className="language-Javascript">
                [ "Bitcoin1BTC's today's price $36,729.12",<br /> "Shiba Inu2SHIB's
                today's price $0.00002072",<br /> "ShibaDoge3SHIBDOGE's today's price
                $0.0...00014",<br /> "TABOO TOKEN4TABOO's today's price $0.003571",<br />
                "SpookyShiba5SPOOKYSHIBA's today's price $0.0...01112",<br />
                "Bitgert6BRISE's today's price $0.0000002503",<br /> "Fantom7FTM's
                today's price $2.03", <br />"Ethereum8ETH's today's price $2,378.92",<br />
                "Terra9LUNA's today's price $52.44", <br />"GamingShiba10GAMINGSHIBA's
                today's price $0.0...01738",<br /> "BNB11BNB's today's price $383.63",<br />
                "Polygon12MATIC's today's price $1.60", <br />"Solana13SOL's today's
                price $89.35",<br /> "Gala14GALA's today's price $0.1811",<br /> "The
                Sandbox15SAND's today's price $3.32",<br /> "Cardano16ADA's today's
                price $1.03",<br /> "Axie Infinity17AXS's today's price $46.17",<br />
                "Anchor Protocol18ANC's today's price $1.39",<br /> "Shinji
                Inu19SHINJI's today's price $0.0...00081",<br /> "PancakeSwap20CAKE's
                today's price $7.08",<br /> "BitTorrent21BTT's today's price
                $0.002275", <br />"Decentraland22MANA's today's price $2.19",<br />
                "Dogecoin23DOGE's today's price $0.1398", <br />"Band Protocol24BAND's
                today's price $3.35",<br /> "Nervos Network25CKB's today's price
                $0.01448", <br />"Acala Token26ACA's today's price $1.60",<br /> "Binance
                USD27BUSD's today's price $0.9991",<br /> "XRP28XRP's today's price
                $0.6011",<br /> "Crypto.com Coin29CRO's today's price $0.3947",<br />
                "Polkadot30DOT's today's price $17.81" ]<br />
              </code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
}
