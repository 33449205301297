import sentinel1 from "../assets/sentinel1.png";
import folder from "../assets/folder.png";
//

export default function Sentinel() {
    var mock = `
    mock "tfplan/v2" {
        module {
          source = "../../mock-tfplan-v2.sentinel"
        }
      }
      
      test {
          rules = {
              main = true
          }
    }
    `
  var maintf = `resource "aws_instance" "example" {
    ami           = "ami-2757f631"
    instance_type = "t2.micro"
  }
  
  resource "aws_vpc" "vpc" {
    cidr_block           = "10.0.0.0/16"
  }
  
  terraform {
    cloud {
      organization = "example-org"
  
      workspaces {
        name = "testworkspacename"
      }
    }
  }
`;

  var sentinel = `import "tfplan/v2" as tfplan

ec2_instances = filter tfplan.resource_changes as _, rc {
    rc.type is "aws_instance" and
        (rc.change.actions contains "create" or rc.change.actions is ["update"])
}

mandatory_tags = [
    "Name",
]

allowed_types = [
    "t2.micro",
    "t2.small",
    "t2.medium",
]

instance_type_allowed = rule {
    all ec2_instances as _, instance {
        instance.change.after.instance_type in allowed_types
    }
}

main = rule {
    (instance_type_allowed) else true
}
  `;

  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              Terraform Testing
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Sentinel Quick Guide
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Hashicorp Sentinel is a policy-as-code framework that can be used
            for testing and policy enforcement. This guide will help you setup
            sentinal and write your first test.
            <br></br>
            1. Check if terraform version is greater than 1.1, this is required
            for terraform workspace cli workflow. Terraform workspace offer ui
            and cli ways to manage state remotely and various other capabilities
            to trigger terraform, get mock files for testing etc.
            <br></br>
            2. Login in to terraform CLI using "terraform login" command and the
            token supplied by tf.
            <br></br>
            3. Following is a sample main.tf, obv 💾
          </p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">{maintf}</code>
            </pre>
          </div>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            4. Create a sentinel file (name as example.sentinel for this exercise) to test this tf. The main.tf simply
            creates an EC2 instance and a vpc. The below test file mandates that
            the instance type can only be of the mentioned type in allowed_types
            variable. Example file below, 💾
          </p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">{sentinel}</code>
            </pre>
          </div>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            5. Create a new workspace on CLI using the command "terraform
            workspace new testworkspacename"
            <br></br>
            6. Terraform cloud block in main.tf above connects the terraform org
            and the workspace, can be added to any .tf.
            <br></br>
            7. Run "terraform init" and "terraform apply" for the first time and
            the apply approval can be denied if resources are not required to be
            created at this point.
            <br></br>
            8. On workspace ui under /runs download sentinel mocks
          </p>
          <figure className="content-center">
            <img className="w-full rounded-lg" src={sentinel1} />
          </figure>
          <p className="mt-8 text-xl text-gray-500 leading-8">
              9. For this example let us use the mock tfplan, however sentinel can be used to test state aswell. 
              Extract mock-tfplan-v2.sentinel and place it in the root. 
              <br></br>
              10. Create a folder called test/example (name should match with the sentinel test file). 
              Create a file and name it as pass.hcl. This file will be used to pass the mock data. Following is 
              the example code.
              <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">{mock}</code>
            </pre>
            <p className="mt-8 text-xl text-gray-500 leading-8">
                Finally the folder structure should look as below. Now the command "sentinel test example.sentinel" 
                will run the test and show result in console. This structure can now be extended to enforce policies and 
                also used to test the code.
            </p>
            <figure className="content-center">
            <img className="w-full rounded-lg" src={folder} />
          </figure>
          </div>
          </p>
        </div>
      </div>
    </div>
  );
}
