import gremlin1 from '../assets/gremlin1.png';
import gremlin1table from '../assets/gremlin1table.png';
export default function GraphDB() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              AWS Neptune
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Introduction to GraphDB
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Lets consider the personalisation problem in a simple app where a user can signup, read articles, receive notifications on new articles,
            and the articles must have a tiny label to show if the user has read the article fully or not. 
          </p>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            In a typical relational database system this would be modeled as multiple tables and relationships.
            If there are 10k concurrent users on the app reading articles, bookmarking articles, closing app and so on
            it would be highly resource heavy to manage an RDBMS and serve personalisation. Also the performance for a 
            scenario with RDBMS is a topic for debate.
          </p>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            What if we use something like dynamodb, a nosql db. This would require multiple indexed tables, with 
            backend constantly finding the right answer for the queries. Also if we think of key value store, the values
            are hidden until unpacked via key. This is suitable for a aggregation, document serving etc however for a
            scenario that requires deep search with a number of relationships.
          </p>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            GraphDB provides an intuitive way of thinking this problem. If we view users as nodes or vertices and the 
            relationships as edges. Then really the problem of personalisation is nothing but a graph with 
            many vertices and edges. GraphDB shines because any number of properties can be added to a vertex or 
            edge at any point in time. This is handy because IRW change is inevitable and even after significant user adoption a new relationship 
            attribute (edge prop) for a vertex can be created to serve the new use case.
            The below diagram is a simple representation of graph structure to help visualise the concept.
          </p>
          <figure>
            <img
              className="w-full rounded-lg"
              src={gremlin1}
              alt=""
              width={1310}
              height={873}
            />
            <figcaption className="font-thin">Simple graph to show vetrices and edges</figcaption>
          </figure>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            In the next article I intend to cover AWS Neptune in detail. However, for the sake of completeness 
            and help understand graphDB better, below is a tabular representation of the DB. 
          </p>
          <figure className="content-center">
            <img
              className="w-1/2 rounded-lg"
              src={gremlin1table}
             
            />
            <figcaption className="font-thin">CSV representation</figcaption>
          </figure>
        </div>
      </div>
    </div>
  )
}
