import gremlin1 from "../assets/gremlin1.png";
import routes1 from "../assets/websocket/routes.png";
import stage1 from "../assets/websocket/stage.png";
import csession from "../assets/websocket/clientsession.png";
import ssession from "../assets/websocket/serverconnect.png";

export default function MLDatasets() {
  var sigv4 = `
    // Definitions
    var crypto = require('crypto');
    
    function sign(key, message) {
        return crypto.createHmac('sha256', key).update(message).digest();
    }
    
    function getSignatureKey(key, dateStamp, regionName, serviceName) {
        kDate = sign('AWS4' + key, dateStamp);
        kRegion = sign(kDate, regionName)
        kService = sign(kRegion, serviceName);
        kSigning = sign(kService, 'aws4_request');
        return kSigning;
    }

    // This can be retrieved using stsassumerole() from cognito
    accessKey = "YOURACCESSKEY";
    secretKey = "YourSecretKey";
    sessionToken = "YourCognitoFederatedIdentitySessionToken";

    region = "eu-west-2";
    serviceName = "execute-api";

    // example date 20180518T210317Z
    var now = new Date();
    amzdate = now.toJSON().replace(/[-:]/g, "").replace(/\.[0-9]*/, "");
    datestamp = now.toJSON().replace(/-/g, "").replace(/T.*/, "");

    // prepare to send a HTTP request an endpoint
    apiMethod = "POST";
    apiHost = "your-api-gateway.execute-api.eu-west-2.amazonaws.com";
    apiEndpoint = "/stage/secure/endpoint";
    apiQueryString = "";

    canonicalHeaders = "host:" + apiHost + "\\nx-amz-date:" + amzdate +
    "\\nx-amz-security-token:" + sessionToken + "\\n";

    signedHeaders = "host;x-amz-date;x-amz-security-token";

    payloadHash = crypto.createHash('sha256').update('').digest('hex');

    canonicalRequest = apiMethod + "\\n" + apiEndpoint + "\\n" + apiQueryString +
    "\\n" + canonicalHeaders + "\\n" + signedHeaders + "\\n" + payloadHash;

    console.log('preparing to invoke canonical request:');
    console.log(canonicalRequest);

    // Create a string to sign
    algorithm = 'AWS4-HMAC-SHA256';
    credentialScope = datestamp + '/' + region + '/' + serviceName + '/' +
    'aws4_request';
    stringToSign = algorithm + '\\n' + amzdate + '\\n' + credentialScope + '\\n' +
    crypto.createHash('sha256').update(canonicalRequest).digest('hex');

    // Create the signing key using the function defined above.
    signingKey = getSignatureKey(secretKey, datestamp, region, serviceName);

    // Sign the string_to_sign using the signing_key
    signature = crypto.createHmac('sha256', signingKey).update(stringToSign).digest(
    'hex');

    // Finally Create header
    authorizationHeader = algorithm + ' ' + 'Credential=' + accessKey + '/' +
    credentialScope + ', ' + 'SignedHeaders=' + signedHeaders + ', ' +
    'Signature=' + signature;
`;

  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              Machine Learning
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Five Large ML Datasets
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Following are some of the 5 interesting large datasets for computer vision enthusiasts.
            <li >              
                <a href='https://data.vision.ee.ethz.ch/cvl/rrothe/imdb-wiki/' className="text-indigo-600" target="_blank">IMDB WIKI</a> - 500K+ images of human faces with gender and age labels for training.
                
            </li>
            <li >              
                <a href='http://im.itu.edu.pk/firearms-detection/?fbclid=IwAR1UjWoMejq_C57y7jT1z-n6Llg5CXNhp0yzblih441CXpbuabfUYY3oyXc' className="text-indigo-600" target="_blank">ITU Firearm Dataset</a> 
                - 11K labelled images identifying various firearms carried by humans. Dataset can be used for content moderation and security.
                
            </li>
            <li >              
                <a href='http://pic2recipe.csail.mit.edu/' className="text-indigo-600" target="_blank">Recipe1M+</a> 
                - 1M+ cooking recipes with 13M+ food images, code and dataset available at the portal.
                
            </li>
            <li >              
            <a href='http://places.csail.mit.edu/index.html' className="text-indigo-600" target="_blank">2.5M+ Places</a> 
                - Images of places across 200+ scenes. 
            </li>
            <li >              
            <a href='https://www.kaggle.com/aravindram11/funsdform-understanding-noisy-scanned-documents' className="text-indigo-600" target="_blank">Noisy scanned documents</a> 
                - Not so large however this is a good dataset of scanned documents. Use cases can range from categorising archive documents to text extraction.
            </li>
          </p>
        </div>
      </div>
    </div>
  );
}
