import gremlin1 from "../assets/gremlin1.png";
import routes1 from "../assets/websocket/routes.png";
import stage1 from "../assets/websocket/stage.png";
import csession from "../assets/websocket/clientsession.png";
import ssession from "../assets/websocket/serverconnect.png";
import folder from "../assets/20jan/20janfolderstructure.png";
import layerconfig from "../assets/20jan/20janlayerconfig.png";
import addlayer from "../assets/20jan/20janaddlayer.png";
import succ from "../assets/20jan/20jansuccess.png";
export default function KMS2() {
  var customhook = ` import { useState, useEffect } from 'react';

  function useYourTimeWisely(activity) {
    const [isUseful, setIsUseful] = useState(null);
    useEffect(() => {
      function checkUsefulnessAPI(activity) {
        //API Call
        setIsUseful(receivedStatus);
      }  
    });
    return isUseful;
  }
`;

  var hookcall = ` function UsefulTime(props) {
    const isUseful = useYourTimeWisely(props.activity);
  
    if (!isUseful) {
      return 'Waste of time ..';
    } else {
        return 'good job!';
    }
  }
`;

  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              AWS KMS
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Encrypt and Decrypt Messages with KMS
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            This is a simple example to show how a message can be encrypted and
            then decrypted using symmtric CMK on KMS.
            <br />
            <br />
          </p>
          <div className="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">
                const aws = require("aws-sdk"); <br />
                const kms = new aws.KMS(&#123; region: "eu-west-2" &#125;);<br
                />
                const source = JSON.stringify(&#123;<br />
                userId: 1,<br />
                id: 1,<br />
                title: "delectus aut autem",<br />
                completed: false<br />
                &#125;);<br />
                processing();<br />
                <br />
                async function processing() &#123;<br />
                const encryptedValue = await encrypt(source);<br />
                console.log(encryptedValue);<br />
                const decryptedValue = await decrypt(encryptedValue);<br />
                console.log(decryptedValue);<br />
                &#125;<br />
                <br />
                async function encrypt(source) &#123;<br />
                const params = &#123;<br />
                KeyId: "534ba6bc-test-4ac9-a633-f29f4b1fa6ff",<br />
                Plaintext: source<br />
                &#125;;<br />
                const &#123; CiphertextBlob &#125; = await
                kms.encrypt(params).promise();<br />
                return CiphertextBlob.toString("base64");<br />
                &#125;<br />
                <br />
                async function decrypt(source) &#123;<br />
                const params = &#123;<br />
                KeyId: "534ba6bc-test-4ac9-a633-f29f4b1fa6ff",<br />
                CiphertextBlob: Buffer.from(source, "base64")<br />
                &#125;;<br />
                const &#123; Plaintext &#125; = await
                kms.decrypt(params).promise();<br />
                return Plaintext.toString();<br />
                &#125;<br />
              </code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
}
