export default function Staticstability() {
    return (
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div
            className="relative h-full text-lg max-w-prose mx-auto"
            aria-hidden="true"
          >
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
                AWS DR
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Static Stability with Route 53 ARC
              </span>
            </h1>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Satic stability as a general architectural principal is to ensure that the 
              resources continues to operate in spite of its dependency failures. This principle in 
              real world would have to be implemented in multi-layers. Couple of basic principles: 
              (1) Decouple control plane dependencies in data plane, (2) Loosely couple workload 
              dependencies. 
              <br></br>
              <br></br>
                <b>Route 53 ARC</b>
                <br></br>
                In a failover scanario, route 53 is traditionally used to route traffic to healthy targets. 
                However the health checks are not fully reliable if the whole region is in a failed state.
                In such scenarios applying DR plan manually is much more efficient. In this scenario, the main
                dependencies are the availability of route 53, its ability to serve traffic and route 53 must be 
                available for manual record changes. 
                Route 53's data plane is responsible for responding to DNS queries, the data plane is highly-highly reliable.
                Control plane on Route 53 operates the APIs that updates records, there could be scenarios when the 
                call to control plane fail. Route 53 Application Recovery Controller helps handling such scenarios. ARC can be 
                used to health check vertically or horizontally, for example check if all the resources in a region is 
                healthy or check if dynamodb tables are available across multiple regions.
                <br></br>
                <br></br>
                Recovery groups within ARC can be created with resources grouped as "cells". Recovery groups 
                can then be used to perform readiness check. Readiness checks can be for a single region or multi-region
                or single resource across regions. Routing control within ARC can be used to manually control failover routing.
                <br></br>
                <br></br>
                <b>So what did we learn?</b>
                <br></br>
                In order to improve resiliency the architecture must follow static stability principles, i.e. in DR sense decouple 
                resource dependencies that are part of failover critical path. Route 53 ARC offers the ability to manually 
                monitor health of resources and apply failover routing policies.
            </p>
          </div>
        </div>
      </div>
    );
  }
  