import gremlin1 from "../assets/gremlin1.png";
import routes1 from "../assets/websocket/routes.png";
import stage1 from "../assets/websocket/stage.png";
import csession from "../assets/websocket/clientsession.png";
import ssession from "../assets/websocket/serverconnect.png";

export default function Customhooks() {
  var customhook = ` import { useState, useEffect } from 'react';

  function useYourTimeWisely(activity) {
    const [isUseful, setIsUseful] = useState(null);
    useEffect(() => {
      function checkUsefulnessAPI(activity) {
        //API Call
        setIsUseful(receivedStatus);
      }  
    });
    return isUseful;
  }
`;

var hookcall = ` function UsefulTime(props) {
    const isUseful = useYourTimeWisely(props.activity);
  
    if (!isUseful) {
      return 'Waste of time ..';
    } else {
        return 'good job!';
    }
  }
`;


  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              ReactJS
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              useYourTimeWisely() Custom Hooks
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            In the scheme of react component lifecycle hooks are invoked everytime when a component is rendered. 
            During mounting this would occur at the stage when a component is mounted (after constructor and render),
            during updating this would occur after render and DOM updates when the component is being updated and during
            unmount this will be invoked when component will unmount. <br></br>
            Hooks are nothing by JS functions with additional rules attached. Following are a few rules: <br></br>
            1. Hooks can be invoked only from functional components at the top level. <br></br>
            2. Hooks cannot be invoked from an event handler and cannot be invoked conditionally. <br></br>
            3. Hooks can be invoked from custom hooks. <br></br>
            4. When multiple hooks are defined inside a component the order is preserved as defined. 
            <br></br>
            Custom Hooks:<br></br>
            As mentioned above hooks are nothing but JS functions with additional rules. Below is an example of
            a hook that can be used to findout if a user is using their time wisely. <br></br>
          </p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">{customhook}</code>
            </pre>
          </div>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Notice the custom hook name starts with the keyword 'use', this is an additional rule for custom hooks.<br></br>
            Now the above custom hook can invoked like any other hook. Example below: 
          </p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">{hookcall}</code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
}
