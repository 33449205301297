import gremlin1 from "../assets/gremlin1.png";
import routes1 from "../assets/websocket/routes.png";
import stage1 from "../assets/websocket/stage.png";
import csession from "../assets/websocket/clientsession.png";
import ssession from "../assets/websocket/serverconnect.png";

export default function Chain() {
  var chain = `
  namespace ChainOfResponsibilityPattern {
    export class Handler {
        private handler: Handler;
        private req: number;

        constructor(req: number) {
            this.req = req;
        }

        public setHandler(handler: Handler): void {
            this.handler = handler;
        }

        public operation(msg: string, req: number): void {
            if (req <= this.req) {
                this.handlerRequest(msg)
            } else if (this.handler !== null && this.handler !== undefined) {
                this.handler.operation(msg, req);
            }
        }

        public handlerRequest(msg: string): void {
            throw new Error("Abstract method!");
        }
    }
    export class ConcreteHandler1 extends Handler {
        constructor(req: number) {
            super(req);
        }
        public handlerRequest(msg: string) {
            console.log("Message (ConcreteHandler1) :: ", msg);
        }
    }
    export class ConcreteHandler2 extends Handler {
        constructor(req: number) {
            super(req);
        }
        public handlerRequest(msg: string) {
            console.log("Message :: (ConcreteHandler2) ", msg);
        }
    }
    export class ConcreteHandler3 extends Handler {
        constructor(req: number) {
            super(req);
        }
        public handlerRequest(msg: string) {
            console.log("Message :: (ConcreteHandler3) ", msg);
        }
    }
}
`;

  var functioncall = `namespace ChainOfResponsibilityPattern {
	export namespace Demo {

		export function show() : void {
		    var h1: ChainOfResponsibilityPattern.Handler,
				h2: ChainOfResponsibilityPattern.Handler,
				h3: ChainOfResponsibilityPattern.Handler,
				reqs: number[],
				i: number,
				max: number;

			reqs = [2, 7, 23, 34, 4, 5, 8, 3];

			h1 = new ChainOfResponsibilityPattern.ConcreteHandler1(3);
			h2 = new ChainOfResponsibilityPattern.ConcreteHandler2(7);
			h3 = new ChainOfResponsibilityPattern.ConcreteHandler3(20);

			h1.setHandler(h2);
			h2.setHandler(h3);

			for (i = 0, max = reqs.length; i < max; i += 1) {
				h1.operation("operation is fired!", reqs[i]);
			}

		}
	}
}`;

  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              Typescript Design Patterns
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Chain of Responsibility Pattern
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Chain of responsibility mimcs the real world delegation in a hierarchical organisation in order to 
            simplify the handling of requests. For instance in a scenario where a service is 
            required to check grammar for various languages such as English, Tamil, French and spanish. 
            In a generic sense the request needs to be handled by a "handler" however 
            the implementations would be different dependending on the parameter passed. Chain of
            responsibility pattern helps solve problems such as this elegantly. In a traditional
            sense the handler would define an inteface for implmenetation and concrete classes would 
            implement specific implementations. In the below example request 1-3 will be processed by
            ConcreteHandler1, 4-7 by ConcreteHandler2 and 8-20 by ConcreteHandler3.<br>
            </br>
            Chain Definition:
          </p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">{chain}</code>
            </pre>
          </div>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Function call:
          </p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">{functioncall}</code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
}
