import gremlin1 from "../assets/gremlin1.png";
import routes1 from "../assets/websocket/routes.png";
import stage1 from "../assets/websocket/stage.png";
import csession from "../assets/websocket/clientsession.png";
import ssession from "../assets/websocket/serverconnect.png";
import folder from "../assets/20jan/20janfolderstructure.png";
import layerconfig from "../assets/20jan/20janlayerconfig.png";
import addlayer from "../assets/20jan/20janaddlayer.png";
import succ from "../assets/20jan/20jansuccess.png";
export default function Cognito2() {
  var customhook = ` import { useState, useEffect } from 'react';

  function useYourTimeWisely(activity) {
    const [isUseful, setIsUseful] = useState(null);
    useEffect(() => {
      function checkUsefulnessAPI(activity) {
        //API Call
        setIsUseful(receivedStatus);
      }  
    });
    return isUseful;
  }
`;

  var hookcall = ` function UsefulTime(props) {
    const isUseful = useYourTimeWisely(props.activity);
  
    if (!isUseful) {
      return 'Waste of time ..';
    } else {
        return 'good job!';
    }
  }
`;

  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              AWS Cognito
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Implement Cognito Auth Journey
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Following snippets show how to authenticate a user and also
            implement forgot password journey.
            <br />
            <br />
            Authenticate:
          </p>
          <div className="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">
                const AWS = require("aws-sdk");<br />
                var AmazonCognitoIdentity =
                require("amazon-cognito-identity-js");<br />
                var CognitoUserPool = AmazonCognitoIdentity.CognitoUserPool;<br
                />
                var authenticationData = &#123;<br />
                Username: "testing",<br />
                Password: "New_11password!@@"<br />
                &#125;;<br />
                var authenticationDetails = new
                AmazonCognitoIdentity.AuthenticationDetails(<br />
                authenticationData<br />
                );<br />
                var poolData = &#123;<br />
                UserPoolId: "eu-west-2_test2jfge",<br />
                ClientId: "75ttd4vfvokptesti87ev7rrbc"<br />
                &#125;;<br />
                var userPool = new
                AmazonCognitoIdentity.CognitoUserPool(poolData);<br />
                var userData = &#123;<br />
                Username: "testing",<br />
                Pool: userPool<br />
                &#125;;<br />
                var cognitoUser = new
                AmazonCognitoIdentity.CognitoUser(userData);<br />
                cognitoUser.authenticateUser(authenticationDetails, &#123;<br />
                onSuccess: function(result) &#123;<br />
                var accessToken = result.getAccessToken().getJwtToken();<br />
                console.log(accessToken);<br />
                AWS.config.credentials.refresh(error =&gt; &#123;<br />
                if (error) &#123;<br />
                console.error(error);<br />
                &#125; else &#123;<br />
                cognitoUser.getUserAttributes(function(err, result) &#123;<br />
                if (err) &#123;<br />
                alert(err.message || JSON.stringify(err));<br />
                return;<br />
                &#125;<br />
                for (i = 0; i &#60; result.length; i++) &#123;<br />
                console.log(<br />
                "attribute " +<br />
                result[i].getName() +<br />
                " has value " +<br />
                result[i].getValue()<br />
                );<br />
                &#125;<br />
                &#125;);<br />
                &#125;<br />
                &#125;);<br />
                &#125;,<br />
                onFailure: function(err) &#123;<br />
                alert(err.message || JSON.stringify(err));<br />
                &#125;<br />
                &#125;);<br />
              </code>
            </pre>
          </div>

          <p className="mt-8 text-xl text-gray-500 leading-8">
            Forgot Password:
          </p>
          <div className="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">
                var AmazonCognitoIdentity =
                require("amazon-cognito-identity-js");<br />
                var CognitoUserPool = AmazonCognitoIdentity.CognitoUserPool;<br
                />
                var poolData = &#123;<br />
                UserPoolId: "eu-west-2_KYh04jfge",<br />
                ClientId: "75ttd4vfvokpp581i87ev7rrbc"<br />
                &#125;;<br />
                var userPool = new
                AmazonCognitoIdentity.CognitoUserPool(poolData);<br />
                var userData = &#123;<br />
                Username: 'testing',<br />
                Pool: userPool,<br />
                &#125;;<br />
                var cognitoUser = new
                AmazonCognitoIdentity.CognitoUser(userData);<br />
                cognitoUser.forgotPassword(&#123;<br />
                onSuccess: function(data) &#123;<br />
                // successfully initiated reset password request
                <br />console.log("CodeDeliveryData from forgotPassword: " +
                data);<br />
                &#125;,<br />
                onFailure: function(err) &#123;<br />
                console.log(err.message || JSON.stringify(err));<br />
                &#125;,<br />
                &#125;<br />
                );<br />
                // callback to confirm password
                <br />function inputVerificationCode () &#123;<br />
                var newPassword = "New_11password!@@";<br />
                cognitoUser.confirmPassword('475921', newPassword, &#123;<br />
                onSuccess() &#123;<br />
                console.log("Password confirmed!");<br />
                &#125;,<br />
                onFailure(err) &#123;<br />
                console.log(err);<br />
                &#125;<br />
                &#125;);<br />
                &#125;<br />
              </code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
}
