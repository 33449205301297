import gremlin1 from "../assets/gremlin1.png";
import routes1 from "../assets/websocket/routes.png";
import stage1 from "../assets/websocket/stage.png";
import csession from "../assets/websocket/clientsession.png";
import ssession from "../assets/websocket/serverconnect.png";
import folder from "../assets/20jan/20janfolderstructure.png";
import layerconfig from "../assets/20jan/20janlayerconfig.png";
import addlayer from "../assets/20jan/20janaddlayer.png";
import succ from "../assets/20jan/20jansuccess.png";
import kmsconsole from "../assets/21jan/kmsconsole.png";
export default function KMS1() {
  var customhook = ` import { useState, useEffect } from 'react';

  function useYourTimeWisely(activity) {
    const [isUseful, setIsUseful] = useState(null);
    useEffect(() => {
      function checkUsefulnessAPI(activity) {
        //API Call
        setIsUseful(receivedStatus);
      }  
    });
    return isUseful;
  }
`;

  var hookcall = ` function UsefulTime(props) {
    const isUseful = useYourTimeWisely(props.activity);
  
    if (!isUseful) {
      return 'Waste of time ..';
    } else {
        return 'good job!';
    }
  }
`;

  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              AWS KMS
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              KMS Introduction and CLI Example
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            KMS an introduction:
            <br />
            AWS Key Management Service offers a simpler way to create and manage
            custom keys. Custom keys are referred as "customer managed keys" and
            it also allows to manage both asymmetric and symmetric keys. When a
            resource is maked as encrypted at rest, AWS creates an "AWS managed
            key" for that resource which is available to view but cannot be
            altered. Key can also be created on a custom key store backed by HSM
            cluster, this is useful when the key material is required to be
            outside the estate. In case of KMS a key is a logical holder of a
            key material which is the actual private key.
            <br />
            <br />
            Symmetric and Asymmetric Keys:
            <br />
            Symmetric keys (obviously) are used for both encryption and
            decryption, asymmetric keys are used for signing a message and
            validating the signature. In case of symmetric keys, the key in
            itself cannot be extracted and shared thus the only way to share the
            key is by adding another AWS account to the KMS key. Refer to the
            image below. Asymmetric keys are of public private pairs, and the
            public can be copied and shared with external parties for signature
            verification. Another neat feature that AWS offers is the ability to
            rotate the symmetric keys automatically although the rotation can
            only be set to yearly frequency.
            <br />
            <br />
            Create Key on Console: <br />
            Role to manage key, use key and external AWS account trusts can be
            added to the key poicy.
          </p>
          <figure>
            <img
              className="w-full rounded-lg"
              src={kmsconsole}
              alt=""
              width={1310}
              height={873}
            />
          </figure>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            CLI Ecrypt and Decrypt example:
            <br />
            The below is a CLI encrypt and decrypt example.
          </p>
          <div className="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">
                //Encrypt<br />
                aws kms encrypt \ <br />
                --key-id 534ba6bc-test-4ac9-a633-f29f4b1fa6ff \<br />
                --plaintext fileb://mail.txt \<br />
                --output text \<br />
                --query CiphertextBlob | base64 \<br />
                --decode &gt; ExampleEncryptedFile<br />
                <br />
                <br />
                //Decrypt<br />
                aws kms decrypt \<br />
                --ciphertext-blob fileb://exampleencryptedfile \<br />
                --key-id 534ba6bc-test-4ac9-a633-f29f4b1fa6ff \<br />
                --output text \<br />
                --query Plaintext | base64 \<br />
                --decode &gt; decryptedfile<br />
              </code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
}
