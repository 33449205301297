import gremlin1 from "../assets/gremlin1.png";
import gremlin1table from "../assets/gremlin1table.png";
// import "./GraphDB.js";
export default function Presigned() {

    var presigned = `
    const aws = require('aws-sdk');
    const s3 = new aws.S3({
        region: 'eu-west-2',
    })
    var params = {Bucket: 'mysamplebucket', Key: 'images/cover.jpeg', Expires: 60};
    var url = s3.getSignedUrl('getObject', params);
    console.log('The URL is', url);
    `
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              AWS S3
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Short note on Presigned URLs
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Presigned URLs can be very handy to share objects with 3rd parties or 
            to share s3 location for frontends to upload/get objects. Media objects
            such as video or audio can stream directly from presigned url which is
            simply 👌
            However, there is a huge problem interms of expiring the presigned URLs. 
            By definition these are public URLs with a timebox expiration - if none 
            specified then 7days expiry. This is simply not sufficient, there no
            mechanism to expire the url upon the first click or first event (upload/download) on the url.
            This cannot be achieved programatically because there is also no way to manually 
            invalidate a presigned url. 
            Having said that, the below is an example of a presigned url with 60 seconds expiry.
            One could also generate a presigned with STS temp credentials. 
          </p>
          <p className="mt-8 text-xl font-bold text-gray-500 leading-8">Generate Presigned URL with 60 Seconds Expiry</p>
          <div class="mt-1 prose prose-slate mx-auto lg:prose-lg">
            <pre>
              <code className="language-Javascript">
              {presigned}
              </code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
}
