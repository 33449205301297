import multiplayer from "../assets/multiplayer/multiplayer.png";
 
export default function Multiplayer() {
    var exjson = `
    {
        "gamesession":"001",
        "initialPlayer":"connectionid1",
        "partnerPlayer":"connectionid2"
    }
    `

  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              AWS APIs
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Multiplayer Game Using AWS 
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            The below is a brief high-level architecture for a 2 player game I drew up using AWS websockets. 
            <br />
            <br />
            <figure>
              <img
                className="w-full rounded-lg"
                src={multiplayer}
                alt=""
                width={1310}
                height={873}
              />
            </figure>
            <br />
            <p>
                Lets assume there are 3 players logged in to the app to play this game. 
                The first two player must be matched in to a game session and the third player 
                must be made to wait until a new player logs in. 
            </p>
            <br />
            <p>
                1. When player 1 logs in establish connection using websocket.
                <br></br>
                2. The websocket must also store the connection id for that user in dynamodb
                <br></br>
                3. Now, the second player logs in. At this point
                the connection id will be different, hence follow step 1 and 2 again 
                for user 2. 
                <br>
                </br>
                <br></br>
                Now, users 1 and 2 have connections established and connection ids stored.
                When the users login to play the game
                check if the store has game sessions with 
                two users matched. If there are waiting users then
                the websocket $connection map must match the waiting
                users. For example, when user1 logs in the record on 
                dynamo will have partner attribute blank and when
                user2 logs in the partner attribute must be updated with 
                user2 connection id. 
                <br></br>
                <br></br>
                Example JSON: {exjson}
                <br></br>
                <br></br>
                4. Store the connection ID of user 2 in dynamodb
                <br></br>
                5. Now user 1 starts playing the game by making a first move.
                <br></br>
                6. Store the first move in dynamodb.
                <br></br>
                7. Send the move info to the connection IDs of user1 and user2. 
                <br></br>
                8. Perform 5, 6, 7 when user2 makes a move. 
                <br></br>
                9. A new user, user3 logs in to the system. However since there are only
                3 users in the system and it is a 2 player game the third
                user would have to wait until another usr joins to start playing.

                <br></br>
                <br></br>
                Note: This design would work for near-realtime games like chess/go however
                it wouldnt work for a real time games like fortnite, although a slightly 
                modified version using appsync might work.
                <br></br>
                Hope this article was informative ☮️
            </p>
          </p>
        </div>
      </div>
    </div>
  );
}
