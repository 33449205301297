import gremlin1 from "../assets/gremlin1.png";
import routes1 from "../assets/websocket/routes.png";
import stage1 from "../assets/websocket/stage.png";
import csession from "../assets/websocket/clientsession.png";
import ssession from "../assets/websocket/serverconnect.png";
import folder from "../assets/20jan/20janfolderstructure.png"
import layerconfig from "../assets/20jan/20janlayerconfig.png"
import addlayer from "../assets/20jan/20janaddlayer.png"
import succ from "../assets/20jan/20jansuccess.png"
export default function LayersE() {
  var customhook = ` import { useState, useEffect } from 'react';

  function useYourTimeWisely(activity) {
    const [isUseful, setIsUseful] = useState(null);
    useEffect(() => {
      function checkUsefulnessAPI(activity) {
        //API Call
        setIsUseful(receivedStatus);
      }  
    });
    return isUseful;
  }
`;

  var hookcall = ` function UsefulTime(props) {
    const isUseful = useYourTimeWisely(props.activity);
  
    if (!isUseful) {
      return 'Waste of time ..';
    } else {
        return 'good job!';
    }
  }
`;

  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              AWS Lambda
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Lambda Layers Example
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Lambda layers offer better way to share libraries across multiple
            lambdas. Most important benefit is the reduction of the lambda size
            which inturn increases the time deployment takes. This post shows a
            simple example to create layers.
            <br />
            <br />
            A. Layer folder structure:
            <br />
            The layer that we are going to create will have axios library
            modules. First create a folder called "nodejs". This is important
            because AWS required the libraries within the nodejs folder in a
            layer. Inside the folder perform the below operations in sequence.
            <div className="mt-1 prose prose-slate mx-auto lg:prose-lg">
              <pre>
                <code className="language-Javascript">
                  npm init
                  <br />
                  # initialise main entrypoint as index.handler and not
                  index.js.
                  <br />
                  npm i -s axios
                  <br />
                </code>
              </pre>
            </div>
          </p>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Now the folder structure should look like the image below.Compress the "nodejs" folder and keep it ready to be uploaded in to a layer.
          </p>
          <figure>
            <img
              className="w-full rounded-lg"
              src={folder}
              alt=""
              width={1310}
              height={873}
            />
          </figure>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            B. Create a Layer.
            <br></br>
            On AWS lambda console use the create layer option to create a layer. Upload the zip file.
            Refer image below.
          </p>
          <figure>
            <img
              className="w-full rounded-lg"
              src={layerconfig}
              alt=""
              width={1310}
              height={873}
            />
          </figure>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            C. Lambda function.
            <br></br>
            To test this write a simple lambda as below, with an axios call to mock endpoint. 
            Do not add libraries to this function.
          </p>
          <div className="mt-1 prose prose-slate mx-auto lg:prose-lg">
              <pre>
                <code className="language-Javascript">
                const axios = require('axios'); <br/>
                exports.handler = async (event) =&gt; &#123;<br/>
                var config = &#123;<br/>
                method: 'get',<br/>
                url: 'https://jsonplaceholder.typicode.com/todos/1',<br/>
                headers: &#123; &#125;<br/>
                &#125;;<br/>

                axios(config)<br/>
                .then(function (response) &#123;<br/>
                console.log(JSON.stringify(response.data));<br/>
                &#125;)<br/>
                .catch(function (error) &#123;<br/>
                console.log(error);<br/>
                &#125;);<br/>

                    const response = &#123;<br/>
                        statusCode: 200,<br/>
                        body: JSON.stringify("test success"),<br/>
                    &#125;;<br/>
                    return response;<br/>
                &#125;;
                </code>
              </pre>
            </div>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            D. Add layer
            <br></br>
            If the above lambda function was deployed and executed it would throw a module not found error. 
            For obvious reasons, however after adding the layer with axios module and redeploying the function,
            the execution will go through because the lambda could access the libraries from the layer.
            Add layer and success response below.
          </p>
          <figure>
            <img
              className="w-full rounded-lg"
              src={addlayer}
              alt=""
              width={1310}
              height={873}
            />
          </figure>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Below is the success reponse from the basic demo purpose console test.
          </p>
          <figure>
            <img
              className="w-full rounded-lg"
              src={succ}
              alt=""
              width={1310}
              height={873}
            />
          </figure>
        </div>
      </div>
    </div>
  );
}
