/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

const faqs = [
  {
    question: "What is Appsync?",
    answer:
      "Appsync is a managed GraphQL service offered by AWS. The service connects seemlessly with datasources such as DynamoDB, OpenSearch, Lambda, RDS and a HTTP endpoint. ",
  },
    {
    question: "Benefits of Appsync",
    answer:
      "Appsync is one of the most powerful services for fullstack development. It offers fully managed serverless GraphQL, offering REST APIs and Websockets.",
  },
{
    question: "Benefits of GraphQL",
    answer:
      "An application with REST APIs backend would be required to communicate with innumerous endpoints. When the application grows managing backend endpoint configurations and backend endpoint versions would be become painful. GraphQL makes it easier with queries, mutations and subscriptions all consolidate in a single endpoint. ",
  },
  {
    question: "Appsync Schema",
    answer:
      "Appsync (GraphQL) schema defines the 'capabilities' that are offered via the endpoint. The schema defines the queries, mutations and the subscriptions that are available. ",
  },
    {
    question: "Appsync Resolvers",
    answer:
      "Resolvers convert GraphQL queries in to format that the data source could parse and execute. Resolvers are written as VTLs withing Appsync, request resolvers convert operations to native datasource format and the response resolvers convert operations response to a valid JSON for client application to consume. Although my recommendation would be to have a simple VTL that would direct the call to a lambda function and let lambda function handle business logic. Building business logic using a VTL resolver would soon lead to a complex procedural backend.",
  },
    {
    question: "Realtime Subscriptions",
    answer:
      "Appsync enables realtime subscriptions aswell as REST APIs in a single endpoint, this is make it extremely powerful. Conversely the traditional API gateway would offer REST apis and websockets as separate API endpoints.",
  },
      {
    question: "Usecases",
    answer:
      "With abilities for real time collaboration and unified endpoint, sky is the limit. However some of the common use cases include real-time chat app, gaming dashboard and app that require offline app sync.",
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Appsync1() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              AWS Appsync
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Appsync an Introduction
            </span>
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                   
                  </>
                  
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
