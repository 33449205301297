export default function Idempotent() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              AWS Patterns
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Idempotent APIs and Retries
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            API calls fail! Albeit the scenarios of network timeouts are rare
            a good architecture must include designs for server side failures. The
            corner stone of frontend to backend call failures are the retries.
            When a user performs an action on the frontend if the frontend
            does not receive a response then the client application must be 
            able to safely retry the operation. However if there are sideeffects 
            to an API operation then the call would not be safe for retry. In JS, impure
            functions cannot be safely retried. 
            <br></br>
            <br></br>
            In case of distributed systems, idempotent APIs refer to the operations having same
            effect (as a single request) to a state irrespective of the number of invocations. 
            When considering a scenario where a client app maybe required to retry, the serverside 
            app must have means to identify if the call is an identical invocation or a new invocation. 
            <br></br>
            <br></br>
            <b>Good Headers:</b>
            <br></br>
            APIs should have headers such as X-Request-Id, X-Correlation-Id and X-Trace-Id defined, 
            so that the request can be primarily traced by the application to account for duplicate calls. 
            The additional benefit of such headers is that they are useful with log analysis. 
            <br></br>
            <br></br>
            <b>No response from server: </b>
            <br></br>
            Consider a scenario when the client app (1) has sent a request to create new state,
            (2) server has received the request and (3) server has processed the request
            however the client app does not receive a valid response from the server, i.e receives
            a 504 response. In this case the client app should be able to safely retry. If the header
            had a trace id, which the server can use to identify if the request was duplicate then 
            the safest way to handle such request is to respond to the client with a semantically 
            equivalent response as though it was the first request. This does mean that a state
            store is maintained in the backend. The alternate would be to respond with a error 
            message to imply that the resource already exist. Handling of duplicate messages would 
            vary based on the usecase. 
            <br></br>
            <br></br>
            <b>More than one actors:</b>
            <br></br>
            In a scenario when more than one user is operating on a state, the decision on 
            a correct response might be complicated. However general guideline is to follow a 
            least surprise response. For example, consider a scenario when two users are using 
            good spread sheet or a trello board. The first user adds a new row, however the client
            app does not receives a response. Hence following the retry rule the client app sends 
            a retry for the first user session. Meanwhile since the server has processed the 
            fist user's request to add a row the second user is now able to see the row. They now
            decide to remove the row and delete it from the client app. Now since the first user has 
            retried, the question would be how should the server app handle the first user request. 
            The answer for this is not so straightforward however in case of trello or google sheet
            they have handled to return a semantically equivalent response, i.e. something like row created. 
            Again the response would be usecase specific. 
            <br></br>
            <br></br>
            To summarise, APIs must be designed to identify a call uniquely, must be idempotent and should be capable 
            of responding with sematically equivalent messages.
          </p>
        </div>
      </div>
    </div>
  );
}
